import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from 'src/app/shared/guard/core.service';
import { LoadingService } from 'src/app/shared/loading.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  myForm: FormGroup;
  submitted=false
  status = '';
  constructor(private formBuilder: FormBuilder,private toastr:ToastrService,private coreService:CoreService,private _loading:LoadingService) { }

  ngOnInit(): void {
    this.myForm = this.formBuilder.group({
      fullName: '',
      emailAddress: '',
      subject: '',
      phoneNumber: '',
      msg:''
    });
  }
  submit(){
    
    this.submitted = true;
    console.log(this.myForm)
    if (!this.myForm.valid) {
      return false;
    } else {
      this.submitted = true;

      let data = {
        // username: this.form.controls.userName.value,
        // password: this.form.controls.password.value,
        "name": this.myForm.controls.fullName.value,
        "email": this.myForm.controls.emailAddress.value,
        "subject": this.myForm.controls.subject.value,
        "phone": this.myForm.controls.phoneNumber.value,
        "message": this.myForm.controls.msg.value
      }
      let url='contactUs';
      this._loading.setLoading(true, url);
      this.coreService.post(url, data).subscribe((data: any) => {
        this._loading.setLoading(false, url);
        this.status = '200'
        this.submitted = false;
        this.toastr.success('Success', 'Your request has sent to our team, they will contact you soon');
        this.myForm.reset()
   
      }, (error) => {
          this._loading.setLoading(false, url);
          this.status = '500'
          console.log(error);
          this.toastr.error("There is some problem as of now, Please try again in some time");
      });

    }
    return true;
  }
}
