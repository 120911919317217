import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/shared/guard/auth.service";
import { CoreService } from "src/app/shared/guard/core.service";

@Component({
  selector: "app-admin-feedback",
  templateUrl: "./admin-feedback.component.html",
})
export class AdminFeedbackComponent implements OnInit {
  feedback:any =[]
  constructor(public coreService: CoreService, private router: Router , 
    private toastr: ToastrService, public authService : AuthService) {}

  ngOnInit(): void {
    this.getAllItem();
    
  }

  

  getAllItem(){
    this.coreService.get('getFeedbackList').subscribe((data: any) => {
      this.feedback = data.details;
    }, (error) => {
      console.log(error)
      // this.toastr.error(error.statusText);
    });
  }
  
  
}
