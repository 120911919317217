import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/shared/guard/auth.service";
import { CoreService } from "src/app/shared/guard/core.service";

@Component({
  selector: "app-admin-events",
  templateUrl: "./admin-events.component.html",
  styleUrls: ["./admin-events.component.css"]
})
export class AdminEventsComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  events:any = [];
  isShowList = false;
  update_item : any = null;
  imageUpload = '';
  constructor(public coreService: CoreService, private router: Router , 
    private toastr: ToastrService,public authService : AuthService) {}

  ngOnInit(): void {
    this.getAllItem();
    this.createForm();
  }

  createForm(){
    this.form = new FormGroup({      
      "event_name": new FormControl('', [Validators.required]),
      "short_description": new FormControl('', [Validators.required]),
      "date": new FormControl('', [Validators.required]),
      "time": new FormControl('', [Validators.required]),
      "location": new FormControl('', [Validators.required]),
      // "image": new FormControl(''),
    });
  }
  get f(){
    return this.form.controls; 
  }

  getAllItem(){
    this.coreService.get('getEventList?page_number=1&page_size=100&search=').subscribe((data: any) => {
      this.events = data.details;
    }, (error) => {
      console.log(error)
      // this.toastr.error(error.statusText);
    });
  }
  submit(){
    
    this.submitted = true;
    console.log(this.form)
    if (this.form.invalid) {
      return false;
    } else {
      this.submitted = true;

      let data = {
        "event_name": this.form.controls.event_name.value,
        "short_description": this.form.controls.short_description.value,
        "date": this.form.controls.date.value,
        "time": this.form.controls.time.value,
        "location": this.form.controls.location.value,
        // "image": this.imageUpload
      }
            
      if(this.update_item){
        data['event_id'] = this.update_item['event_id'];
        this.coreService.put('updateEvent', data).subscribe((data: any) => {
          this.submitted = false;
          this.toastr.success('Success', 'Events updated Successfully');
          this.getAllItem();
          this.form.reset()   
        }, (error) => {
          console.log(error)
          // this.toastr.error(error.statusText);
        });
      }else{
        this.coreService.post('createEvent', data).subscribe((data: any) => {
          this.submitted = false;
          this.toastr.success('Success', 'Events created Successfully');
          this.getAllItem();
          this.form.reset()   
        }, (error) => {
          console.log(error)
          // this.toastr.error(error.statusText);
        });
      }
      this.isShowList = true;
      return true;
    }
    
  }

  deleteItem(item){
    this.coreService.delete('deleteEvent?event_id='+item.event_id).subscribe((data: any) => {
      this.submitted = false;
      this.toastr.success('Success', 'events deleted Successfully');
      this.getAllItem();
    }, (error) => {
      console.log(error)
      // this.toastr.error(error.statusText);
    });
  }
  editItem(item){
    this.isShowList = false;
    this.update_item = item;
    this.form.controls['event_name'].setValue(item.event_name);
    this.form.controls['short_description'].setValue(item.short_description);
    this.form.controls['date'].setValue(item.date);
    this.form.controls['time'].setValue(item.time);
    this.form.controls['location'].setValue(item.location);
  }

  handleFileInput(event, type) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e:any) => {   
            this.imageUpload = e.currentTarget['result'];   
        
      };
    }
  }
}
