import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";


import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views

// components for views and layouts

import { HeaderComponent } from "./layouts/header/header.component";
import { FooterComponent } from "./layouts/footer/footer.component";
import { ContactComponent } from "./views/admin/contact/contact.component";
import { FeedbackComponent } from "./views/admin/feedback/feedback.component";

import { GalleryComponent } from "./views/admin/gallery/gallery.component";
import { BecomeVolunteerComponent } from "./views/admin/become-volunteer/become-volunteer.component";
import { VolunteerComponent } from "./views/admin/volunteer/volunteer.component";
import { DonorsComponent } from "./views/admin/donors/donors.component";
import { CausesComponent } from "./views/admin/causes/causes.component";
import { ProFounderComponent } from "./views/admin/pro-founder/pro-founder.component";
import { AboutComponent } from "./views/admin/about/about.component";
import { InternshipComponent } from "./internship/internship.component";
import { FightingcovidComponent } from "./fightingcovid/fightingcovid.component";
import { CoreService } from "./shared/guard/core.service";
import { AuthGuard } from "./shared/guard/auth.guard";
import { AuthService } from "./shared/guard/auth.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./shared/guard/auth.interceptor";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { AdminBlogsComponent } from "./views/admin/admin-blogs/admin-blogs.component";
import { AdminEventComponent } from "./views/admin/admin-event/admin-event.component";
import { AdminDashboardComponent } from "./views/admin/admin-dashboard/admin-dashboard.component";
import { AdminProjectComponent } from "./views/admin/admin-project/admin-project.component";
import { AdminHeaderComponent } from "./layouts/admin-header/admin-header.component";
import { AdminUsersComponent } from "./views/admin/admin-users/admin-users.component";
import { BlogDetailsComponent } from "./views/admin/blog-details/blog-details.component";
import { AdminVolunteerComponent } from "./views/admin/admin-volunteer/admin-volunteer.component";
import { from } from "rxjs";
import { VideoComponent } from "./views/admin/video/video.component";
import { AdminEventsComponent } from "./views/admin/admin-events/admin-events.component";
import { AdminInternshipComponent } from "./views/admin/admin-internship/admin-internship.component";
import { AdminMembershipComponent } from "./views/admin/admin-membership/admin-membership.component";
import { AdminFeedbackComponent } from "./views/admin/admin-feedback/admin-feedback.component";
import { GetInvolvedComponent } from "./views/admin/get-involved/get-involved.component";
import { WhatWeDoComponent } from "./views/admin/what-we-do/what-we-do.component";
import { BlogsComponent } from "./views/admin/Blogs/blogs.component";
import { ContactUsComponent } from './views/admin/contact-us/contact-us.component';
import { DonationComponent } from './donation/donation.component';
import { DonationsComponent } from "./views/admin/donations/donations.component";
import { ContributionsComponent } from './contributions/contributions.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NekiKaBazaarComponent } from './neki-ka-bazaar/neki-ka-bazaar.component';
import { BeauticianComponent } from './beautician/beautician.component';
import { GurukulComponent } from './gurukul/gurukul.component';
import { EducationalKitsComponent } from './educational-kits/educational-kits.component';
import { DivineSilaiComponent } from './divine-silai/divine-silai.component';
import { JoinUsComponent } from './views/admin/join-us/join-us.component';
import { DonateBlanketsComponent } from './views/admin/donate-blankets/donate-blankets.component';
import { FeedPoorComponent } from './views/admin/feed-poor/feed-poor.component';
import { ComputerEducationComponent } from './views/admin/computer-education/computer-education.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ThankYouComponent } from './views/admin/thank-you/thank-you.component';
import { ChildEducationComponent } from './views/admin/child-education/child-education.component';
import { AdultEducationComponent } from './views/admin/adult-education/adult-education.component';
import { YogaMeditationComponent } from './views/admin/yoga-meditation/yoga-meditation.component';

//import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    AdminEventsComponent,
    AdminInternshipComponent,
    AdminMembershipComponent,
    // FooterComponent,
    VideoComponent,
    AdminComponent,
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    FeedbackComponent,
    BlogsComponent,
    GalleryComponent,
    BecomeVolunteerComponent,
    VolunteerComponent,
    DonorsComponent,
    CausesComponent,
    ProFounderComponent,
    AboutComponent,
    WhatWeDoComponent,
    GetInvolvedComponent,
    FightingcovidComponent,
    InternshipComponent,
    AdminBlogsComponent,
    AdminEventComponent,
    AdminDashboardComponent,
    AdminProjectComponent,
    AdminHeaderComponent,
    AdminUsersComponent,
    BlogDetailsComponent,
    AdminVolunteerComponent,
    AdminFeedbackComponent,
    ContactUsComponent,
    DonationComponent,
    DonationsComponent,
    ContributionsComponent,
    DisclaimerComponent,
    PrivacyPolicyComponent,
    NekiKaBazaarComponent,
    BeauticianComponent,
    GurukulComponent,
    EducationalKitsComponent,
    DivineSilaiComponent,
    JoinUsComponent,
    DonateBlanketsComponent,
    FeedPoorComponent,
    ComputerEducationComponent,
    ThankYouComponent,
    ChildEducationComponent,
    AdultEducationComponent,
    YogaMeditationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [
    AuthGuard,
    CoreService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
