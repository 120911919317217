import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
  public accessTokenId;
  public userData;
 public role ;
  constructor() {

  }
  setLocalStorage(data) {
    this.userData = data;
    localStorage.setItem('user_info', JSON.stringify(this.userData));
    localStorage.setItem('hospitalId', JSON.stringify(this.userData.hospital_id));
    localStorage.setItem('customerAccessToken', data.access_token);
    this.accessTokenId = localStorage.getItem('customerAccessToken');
  }
  setRole(role) {
    this.role = role;
    localStorage.setItem('role', role);
  }
  clearLocalStorage() {
    this.userData = null;
    this.accessTokenId = '';
    localStorage.clear();
    localStorage.removeItem("currentMenu");
    localStorage.removeItem("menus");
    localStorage.removeItem("user_info");
    localStorage.removeItem('customerAccessToken');

    localStorage.removeItem("hospitalId");
    localStorage.removeItem('role');
    localStorage.removeItem('privilege');
  }
  getRole() {
    return localStorage.getItem('role');
  }
  setPrivileges(data){
    localStorage.setItem('privilege', JSON.stringify(data));
  }
  getPrivileges(){
    // return JSON.parse(localStorage.getItem('privilege'));
  }
}
