import { Component, OnInit } from '@angular/core';
// declare var google: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // var mapProp= {
    //   center:new google.maps.LatLng(51.508742,-0.120850),
    //   zoom:5,
    // };
    // var map = new google.maps.Map(document.getElementById("googleMap"),mapProp);
  }

}
