import { Component, OnInit } from "@angular/core";
import * as jQuery from 'jquery';

declare var $;
@Component({
  selector: "app-donors",
  templateUrl: "./donors.component.html",
})
export class DonorsComponent implements OnInit {
  constructor() {}
  donors = [{},{},{}]
  ngOnInit(): void {
   setTimeout(()=>{
    $('.owl-carousel-1col, .text-carousel, .image-carousel, .fullwidth-carousel').each(function() {
      var data_dots = ( $(this).data("dots") === undefined ) ? false: $(this).data("dots");
      var data_nav = ( $(this).data("nav") === undefined ) ? false: $(this).data("nav");
      var data_duration = ( $(this).data("duration") === undefined ) ? 4000: $(this).data("duration");
      $(this).owlCarousel({
          // rtl: THEMEMASCOT.isRTL.check(),
          autoplay: true,
          autoplayTimeout: data_duration,
          loop: true,
          items: 1,
          dots: data_dots,
          nav: data_nav,
          navText: [
              '<i class="pe-7s-angle-left"></i>',
              '<i class="pe-7s-angle-right"></i>'
          ]
      });
    });
   },200)
  }
}
