import { ViewportScroller } from "@angular/common";
import { Component, OnChanges, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import * as jQuery from 'jquery';
import { ToastrService } from "ngx-toastr";
import { CoreService } from "src/app/shared/guard/core.service";
import { LoadingService } from "src/app/shared/loading.service";

declare var $;

@Component({
  selector: "app-donations",
  templateUrl: "./donations.component.html",
  styleUrls: ['./donations.component.css']
})
export class DonationsComponent implements OnInit, OnChanges {

  constructor(private coreService: CoreService,private toastr:ToastrService,private _loading:LoadingService,private router:ActivatedRoute,private scroller:ViewportScroller) { }

  blogsdata: any = [];
  question = [{}, {}, {}, {}]
  activeTab = '1'
  type: any = 'online';
  form: FormGroup;
  money = 0;
  submitted: boolean = false;


  // ngOnInit(): void {
  //   this.createForm()
  //   this.initializedBlogs();
  //   this.router.queryParamMap.subscribe((params) => {
  //     if(params['params']['type']=='DONATEEDUCATIONALKITS'||params['params']['type']=='DONATEHYGIENEANDDIGNITYKIT'||params['params']['type']=='DONATEOLDCLOTHES'){
  //       this.scroller.scrollToAnchor("donatetochildkit")
  //     }
  //   });
  // }
  ngOnInit(): void {
    this.createForm();
    this.initializedBlogs();
  
    this.router.queryParamMap.subscribe((params) => {
      const type = params.get('type');
      if (type === 'DONATEEDUCATIONALKITS' || type === 'DONATEHYGIENEANDDIGNITYKIT' || type === 'DONATEOLDCLOTHES') {
        this.activeTab = type === 'DONATEEDUCATIONALKITS' ? '5' : type === 'DONATEHYGIENEANDDIGNITYKIT' ? '3' : '2';
        this.scroller.scrollToAnchor("donatetochildkit");
      }
    });
  }
  ngOnChanges(): void {
    this.initializedBlogs()
  }

  async initializedBlogs() {
    let blogsdata: any = [
      {
        image: "/assets/img/blogs.svg",
        title: "Donate A Blanket. Save A Life",
        description: `
          Every winter, DBN runs a Blanket Donation Campaign, where we provide high quality warm blankets along with dry ration and dignity kits to the underprivileged living in the open on the streets.
        `
      },
      {
        image: "/assets/images/Rectangle 196.png",
        title: "Donate Hygiene and Dignity Kit for Women",
        description: `
          We at DBN have witnessed these struggles homeless women go through with close quarters. While efforts are being taken to provide safe shelters to this section, we cannot shy away from our responsibilities. Let us strive to improve the lives of homeless women by creating a safe and secure environment for them.
        `
      }, {
        image: "/assets/images/Rectangle 196 (2).png",
        title: "Donate Educational Kit for Children",
        description: `
          We do get a lot of support for children around children’s day. We feel every day is a children’s day. It’s time we who are educated, engage with homeless children and support their education. 
        `
      }, {
        image: "/assets/images/Rectangle 196 (3).png",
        title: "Donate Your Old Clothes",
        description: `
          Our Clothes donation drive is exclusively meant for the children and adults in the hospitals, children living near railway tracks, under flyovers, children of daily wagers at construction sites, under- privileged children in Govt. Hospitals and to some of our city’s most in-need populations. 
        `
      }, {
        image: "/assets/images/Rectangle 196 (4).png",
        title: "Donate food on Death Anniversary of Loved Ones",
        description: `
          DBN will help you to conduct food donation drives on the death anniversary of your near and dear ones. 
        `
      }, {
        image: "/assets/images/Rectangle 196 (1).png",
        title: "Help us to Provide Food to the Hungry",
        description: `
          Majority of the underprivileged patients admitted in Government hospitals are those who come from nearby states are often poor, in several cases family members decide to stay hungry to save money. Join the DBN to provide food to poor patients and their families outside hospitals. 
          With just Rs.550/- you can provide food to 50 people.
        `
      }
    ]
    let data: any = []
    console.log(blogsdata)
    for (let i = 0; i < blogsdata.length; i++) {
      blogsdata[i].description = await this.formateText(blogsdata[i].description)
      data.push(blogsdata[i])
      if (i == blogsdata.length - 1) {
        this.blogsdata = []
        this.blogsdata = data
        console.log(this.blogsdata)
      }

    }
  }

  goToDonation(){
    var targetOffset = $("#donation").offset().top; // Get the target element's offset from the top of the document
    $("html, body").animate({ scrollTop: targetOffset }, 1000);
  }

  formateText(description: any) {
    if (description) {
      return `${description.substring(0, 203)}..`
    }
    return '';
  }

  changeTab(tab: any) {
    this.activeTab = tab
  }
  navigate(templateId) {
    // this.loading = true;
    //loadingStart("Please wait...");

    setTimeout(() => {
      // this.loading = false;
      // loadingStop();
    }, 2000);

    console.log(templateId);
    let nav = $(templateId);
    let value = nav.offset().top - 180;
    if (templateId.includes("donate")) {
      value = nav.offset().top + 180;
    }

    //alert(value);
    jQuery("html, body").animate(
      {
        scrollTop: value,
      },
      200,
      "swing",
      function () {
        // window.location.hash = target
      }
    );
  }
  open(id) {
    id = "" + id + "";
    var x: any = document.getElementById(id);
    var contentId = "#" + id + "";
    if (x.style.display == "none") {
      $(contentId).show();
      var containerClass = "." + id + "";
      $(containerClass).addClass("open-list-group-item");
      let iconClass = "" + containerClass + ' .fa-chevron-down' + "";
      $(iconClass).addClass("fa-chevron-up")
    } else {
      $(contentId).hide();
      var containerClass = "." + id + "";
      $(containerClass).removeClass("open-list-group-item");
      let iconClass = "" + containerClass + ' .fa-chevron-down' + "";
      $(iconClass).removeClass("fa-chevron-up")
    }
  }
  get f() { return this.form.controls; }

  getBlanketDonation(){
    var radios:any = document.getElementsByName('blanket-donation');
    for (var i = 0; i < radios.length; i++) {
      if (radios[i].checked) {
        console.log(radios[i].value);
        if(radios[i].value != 'other'){
          return radios[i].value;
        }else{
          let value:any = document.getElementById('blanket-donation');
          return value?.value
        }
        
      }
    }
    return 0 ;
  }
  getEducationalKitForChildren(){
    var radios:any = document.getElementsByName('educational-kit-for-children');
    for (var i = 0; i < radios.length; i++) {
      if (radios[i].checked) {
        console.log(radios[i].value);
        if(radios[i].value != 'other'){
          return radios[i].value;
        }else{
          let value:any = document.getElementById('educational-kit-for-children');
          return value?.value
        }
      }
    }
    return 0 ;
  }

  getCareKitForHomeless(){
    var radios:any = document.getElementsByName('care-kit-for-homeless');
    for (var i = 0; i < radios.length; i++) {
      if (radios[i].checked) {
        console.log(radios[i].value);
        if(radios[i].value != 'other'){
          return radios[i].value;
        }else{
          let value:any = document.getElementById('care-kit-for-homeless');
          return value?.value
        }
      }
    }
    return 0 ;
  }

  getFoodDonation(){
    var radios:any = document.getElementsByName('food-donation');
    for (var i = 0; i < radios.length; i++) {
      if (radios[i].checked) {
        console.log(radios[i].value);
        if(radios[i].value != 'other'){
          return radios[i].value;
        }else{
          let value:any = document.getElementById('food-donation');
          return value?.value
        }
      }
    }
    return 0 ;
  }

  getHygieneDignityKitForWomen(){
    var radios:any = document.getElementsByName('hygiene-dignity-kit-for-women');
    for (var i = 0; i < radios.length; i++) {
      if (radios[i].checked) {
        console.log(radios[i].value);
        if(radios[i].value != 'other'){
          return radios[i].value;
        }else{
          let value:any = document.getElementById('hygiene-dignity-kit-for-women');
          return value?.value
        }
      }
    }
    return 0 ;
  }
  onRadioSelection(){
    let hygieneDignityKitForWomen = this.getHygieneDignityKitForWomen() 
    let foodDonation = this.getFoodDonation()
    let careKitForHomeless = this.getCareKitForHomeless()
    let educationalKitForChildren = this.getEducationalKitForChildren()
    let blanketDonation = this.getBlanketDonation()
    let donate_for = '';
    let money = 0;
    if(hygieneDignityKitForWomen != ''){
      donate_for = donate_for + 'Hygiene Dignity Kit For Women'
      money = money + Number(hygieneDignityKitForWomen)
    }
    if(foodDonation != ''){
      donate_for = donate_for + 'Food Donation'
      money = money +  Number(foodDonation)
    }
    if(careKitForHomeless != ''){
      donate_for = donate_for + 'Care Kit For Homeless'
      money = money +  Number(careKitForHomeless)
    }
    if(educationalKitForChildren != ''){
      donate_for = donate_for + 'Educational Kit For Children'
      money = money +  Number(educationalKitForChildren)
    }
    if(blanketDonation != ''){
      donate_for = donate_for + 'Blanket Donation'
      money = money +  Number(blanketDonation)
    }

    this.money = money;
  }

  submit() {

    let hygieneDignityKitForWomen = this.getHygieneDignityKitForWomen() 
    let foodDonation = this.getFoodDonation()
    let careKitForHomeless = this.getCareKitForHomeless()
    let educationalKitForChildren = this.getEducationalKitForChildren()
    let blanketDonation = this.getBlanketDonation()
    let donate_for = '';
    let money = 0;
    if(hygieneDignityKitForWomen != ''){
      donate_for = donate_for + 'Hygiene Dignity Kit For Women'
      money = money + Number(hygieneDignityKitForWomen)
    }
    if(foodDonation != ''){
      donate_for = donate_for + 'Food Donation'
      money = money +  Number(foodDonation)
    }
    if(careKitForHomeless != ''){
      donate_for = donate_for + 'Care Kit For Homeless'
      money = money +  Number(careKitForHomeless)
    }
    if(educationalKitForChildren != ''){
      donate_for = donate_for + 'Educational Kit For Children'
      money = money +  Number(educationalKitForChildren)
    }
    if(blanketDonation != ''){
      donate_for = donate_for + 'Blanket Donation'
      money = money +  Number(blanketDonation)
    }
    this.submitted = true
    console.log(this.form);
    if (!this.form.valid) {
      return
    }
    else {
      
      let data = {
        amount:  money, //Number(this.form.controls.money.value),
        currency: 'INR', //this.form.controls.CURRENCY.value,
        receipt: "receipt",
        partial_payment: true,
        aadhar_number: this.form.controls.aadhar_number.value,
        pan_number: this.form.controls.pan_number.value,
        state:this.form.controls.state.value,
        country:this.form.controls.country.value,
        city:this.form.controls.city.value,
        address1:this.form.controls.address1.value,
        address2:this.form.controls.address2.value,
        zip_postal_address:this.form.controls.zip_postal_address.value,
        notes: {
          title: donate_for,//this.form.controls.donate_for.value,
          description: donate_for,//this.form.controls.donation_for.value,
          deadline: null,
          image: null,
          order_status: 0,
        },
      };
      this.coreService.post("createOrder", data).subscribe(
        (data: any) => {
          console.log(data);
          this.submitted = false;
          let RAZORPAY_OPTIONS = {
            key: "rzp_test_qOxkYNT2NQyYeU",
            secret: "eEidhigTsxUy1z74mmquVj0w",
            amount: money, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency:  'INR',//this.form.controls.CURRENCY.value,
            name: this.form.controls.first_name.value + ' ' + this.form.controls.last_name.value ,//this.form.controls.full_name.value,
            description: donate_for,
            image: "/assets/images/Logo9.png",
            order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            handler: (response) => {
              let body = {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              };
              this.coreService.post("createOrderPayments", body).subscribe(
                (resp: any) => {
                  console.log(resp);
                  let url="verifyPaymentSignature";
                  this._loading.setLoading(true, url);
                  this.coreService.post(url, body).subscribe(
                    (res: any) => {
                      this._loading.setLoading(false, url);
                      if (res.message) {$('#myDonateModal').modal('show');
                      this.form.reset();
                      }
                    },
                    (error) => {
                      this._loading.setLoading(false, url);
                      console.log(error);
                      this.toastr.error("There is some problem as of now, Please try again in some time");
                    }
                  );
                },
                (error) => {
                  this.toastr.error("There is some problem as of now, Please try again in some time");
                  console.log(error);
                }
              );
            },
            prefill: {
              name: this.form.controls.first_name.value + ' ' + this.form.controls.last_name.value,
              email: this.form.controls.email.value,
              contact: this.form.controls.phone.value,
            },
            notes: {
              address: "C3/396, First Floor, Janak Puri, New Delhi -110058",
            },
            theme: {
              color: "#3399cc",
            },
          };
          var rzp1 = new this.coreService.nativeWindow.Razorpay(RAZORPAY_OPTIONS);
          rzp1.open();

          rzp1.on("payment.failed", function (response) {
            this.toastr.success("Success", response.error.reason);
            console.log(response);
            console.log(response.error.description);
            console.log(response.error.source);
            console.log(response.error.step);
            console.log(response.error.reason);
            console.log(response.error.metadata.order_id);
            console.log(response.error.metadata.payment_id);
          });
        },
        (error) => {
          console.log(error);
          this.toastr.error("There is some problem as of now, Please try again in some time");
          // this.toastr.error(error.statusText);
        }
      );
    }
  }

  createForm() {
    this.form = new FormGroup({
      "first_name": new FormControl('', [Validators.required]),
      "last_name": new FormControl('', [Validators.required]),
      "email": new FormControl('', [Validators.required]),
      "phone": new FormControl('', [Validators.required]),
      "aadhar_number": new FormControl('', [Validators.required]),
      "pan_number": new FormControl('', [Validators.required]),
      "country": new FormControl('', [Validators.required]),
      "city": new FormControl('', [Validators.required]),
      "state": new FormControl('', [Validators.required]),
      "zip_postal_address": new FormControl('', [Validators.required]),
      "address1": new FormControl('', [Validators.required]),
      "address2": new FormControl('', [Validators.required]),
    });
  }
}
