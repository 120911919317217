import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-contributions',
  templateUrl: './contributions.component.html',
  styleUrls: ['./contributions.component.css']
})
export class ContributionsComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  gotoLink(){
    this.router.navigate(['/involved'], { queryParams: { intern: 'intern' } });    
  }
  gotoLinkDonate(value){
    this.router.navigate(['/donations'], { queryParams: { type: value } });
  }
}
