import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from '../shared/guard/core.service';
import { LoadingService } from '../shared/loading.service';
declare var $;

@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.css']
})
export class DonationComponent implements OnInit {
  type: any='online';
  form: FormGroup;
  submitted:boolean=false;
  constructor(private coreService:CoreService,
    private toastr: ToastrService,
    private _loading:LoadingService) { }

  ngOnInit(): void {
    this.createForm()
  }
  createForm(){
    this.form = new FormGroup({      
      "donate_for": new FormControl('', [Validators.required]),
      "full_name": new FormControl('', [Validators.required]),
      "email": new FormControl('', [Validators.required]),
      "phone": new FormControl('', [Validators.required]),
      "CURRENCY": new FormControl('', [Validators.required]),
      "money": new FormControl('', [Validators.required]),
    });
  }
  copyText(textToCopy: string) {
    //     this.clipboard.copy(textToCopy);
}
donateType(type){
  this.type=type
}
get f() { return this.form.controls; }
submit() {
  this.submitted=true
  console.log(this.form.controls);
  if(!this.form.valid){
    return 
  }
  else{
  let data = {
    amount: Number(this.form.controls.money.value),
    currency: this.form.controls.CURRENCY.value,
    receipt: "receipt",
    partial_payment: true,
    notes: {
      title: "test",//this.form.controls.donate_for.value,
      description: "test",//this.form.controls.donation_for.value,
      deadline: null,
      image: null,
      order_status: 0,
    },
  };
  this.coreService.post("createOrder", data).subscribe(
    (data: any) => {
      console.log(data);
      this.submitted=false;

      let RAZORPAY_OPTIONS = {
        key:  "rzp_test_qOxkYNT2NQyYeU",
        secret: "eEidhigTsxUy1z74mmquVj0w",
        amount: this.form.controls.money.value, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: this.form.controls.CURRENCY.value,
        name: this.form.controls.full_name.value,
        description:this.form.controls.donate_for.value,
        image: "/assets/images/Logo9.png",
        order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: (response) => {
          let body = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };
          this.coreService.post("createOrderPayments", body).subscribe(
            (resp: any) => {
              console.log(resp);
              let url="verifyPaymentSignature";
                  this._loading.setLoading(true, url);
              this.coreService.post(url, body).subscribe(
                (res: any) => {
                  this._loading.setLoading(false, url);
                  if (res.message) {$('#myDonateModal').modal('show');
                  this.form.reset();
                  }
                },
                (error) => {
                  this._loading.setLoading(false, url);
                  console.log(error);
                  this.toastr.error("There is some problem as of now, Please try again in some time");
                }
              );
            },
            (error) => {
              console.log(error);
              this.toastr.error("There is some problem as of now, Please try again in some time");
            }
          );
        },
        prefill: {
          name: this.form.controls.full_name.value,
          email: this.form.controls.email.value,
          contact: this.form.controls.phone.value,
        },
        notes: {
          address: "C3/396, First Floor, Janak Puri, New Delhi -110058",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new this.coreService.nativeWindow.Razorpay(RAZORPAY_OPTIONS);
      rzp1.open();

      rzp1.on("payment.failed", function (response) {
        this.toastr.success("Success", response.error.reason);
        console.log(response);
        console.log(response.error.description);
        console.log(response.error.source);
        console.log(response.error.step);
        console.log(response.error.reason);
        console.log(response.error.metadata.order_id);
        console.log(response.error.metadata.payment_id);
      });
    },
    (error) => {
      console.log(error);
      this.toastr.error("There is some problem as of now, Please try again in some time");
      // this.toastr.error(error.statusText);
    }
  );
}
}
}
