import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/shared/guard/auth.service";
import { CoreService } from "src/app/shared/guard/core.service";

@Component({
  selector: "app-admin-membership",
  templateUrl: "./admin-membership.component.html",
  styleUrls: ["./admin-membership.component.css"]
})
export class AdminMembershipComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  internship:any = [];
  update_item : any;
  imageUpload = '';
  memberType = 0;
  isShowList = true;
  constructor(public coreService: CoreService, private router: Router , 
    private toastr: ToastrService,public authService : AuthService) {}

  ngOnInit(): void {
    this.getAllItem(0);
    this.createForm();
  }

  createForm(){
    this.form = new FormGroup({      
      "member_type": new FormControl('', [Validators.required]),
      "name": new FormControl('', [Validators.required]),
      "dob": new FormControl('', [Validators.required]),
      "mobile_number": new FormControl('', [Validators.required]),
      "email": new FormControl('', [Validators.required]),
      "address": new FormControl('', [Validators.required]),
      "city": new FormControl('', [Validators.required]),
      "state": new FormControl('', [Validators.required]),
      "zipcode": new FormControl('', [Validators.required]),
      "Aadhar_number": new FormControl('', [Validators.required]),
      "Educational_Qualification": new FormControl('', [Validators.required]),
      "profession": new FormControl('', [Validators.required]),
      "ngo_member": new FormControl('', [Validators.required]),
      "amount_pay_yes": new FormControl('', [Validators.required]),
      "amount_pay": new FormControl('', [Validators.required]),
      // "amount_2100": new FormControl(false),
      // "amount_3100": new FormControl(false),
      // "amount_5100": new FormControl(false),
      // "amount_10000": new FormControl(false),
      // "other_amount": new FormControl(false),
      "amount": new FormControl('', [Validators.required]),
      "mode_of_payment": new FormControl('', [Validators.required]),
      // "mode_of_payment_cash": new FormControl(false),
      // "mode_of_payment_cheque": new FormControl(false),
      // "mode_of_payment_netbanking": new FormControl(false),
      // "mode_of_payment_paytm": new FormControl(false),
      // "mode_of_payment_other": new FormControl(false),
      "suggestion": new FormControl('', [Validators.required]),
      "PAN_number": new FormControl('', [Validators.required]),
      "other_contribution": new FormControl('', [Validators.required]),
      "membership_contribution": new FormControl(false),
      // "membership_contribution_quarterly": new FormControl(false),
      // "membership_contribution_half_yearly": new FormControl(false),
      // "membership_contribution_yearly": new FormControl(false),
      // "membership_contribution_other": new FormControl(false),
      "contribute_monthly_amount": new FormControl('', [Validators.required]),
      "activate": new FormControl(false),
      
      // "image": new FormControl(''),
    });
  }
  get f(){
    return this.form.controls; 
  }

  getAllItem(member_type){
    this.memberType = member_type;
    this.coreService.post(`getMemberList?member_type=${member_type}&page_number=1&page_size=1000&search=`, {}).subscribe((data: any) => {
      this.internship = data.details;
    }, (error) => {
      console.log(error)
      // this.toastr.error(error.statusText);
    });
  }
  submit(){
    
    this.submitted = true;
    console.log(this.form)
    if (this.form.invalid) {
      return false;
    } else {
      this.submitted = true;

      let data = {
        "member_type": this.form.controls.member_type.value,
        "name": this.form.controls.name.value,
        "dob": this.form.controls.dob.value,
        "mobile_number": this.form.controls.mobile_number.value,
        "email": this.form.controls.email.value,
        "address": this.form.controls.address.value,
        "city": this.form.controls.city.value,
        "state": this.form.controls.state.value,
        "zipcode": this.form.controls.zipcode.value,
        "Aadhar_number": this.form.controls.Aadhar_number.value,
        "Educational_Qualification": this.form.controls.Educational_Qualification.value,
        "profession": this.form.controls.profession.value,
        "ngo_member": this.form.controls.ngo_member.value,
        "amount_pay_yes": this.form.controls.amount_pay_yes.value,
        "amount_2100": this.form.controls.amount_pay.value == 'amount_2100' ? true : false,
        "amount_3100": this.form.controls.amount_pay.value == 'amount_3100' ? true : false,
        "amount_5100": this.form.controls.amount_pay.value == 'amount_5100' ? true : false,
        "amount_10000": this.form.controls.amount_pay.value == 'amount_10000' ? true : false,
        "other_amount": this.form.controls.amount_pay.value == 'other_amount' ? true : false,
        "other_contribution": this.form.controls.other_contribution.value,
        "amount": this.form.controls.amount.value,
        "mode_of_payment_cash": this.form.controls.mode_of_payment.value == 'mode_of_payment_cash' ? true : false,
        "mode_of_payment_cheque": this.form.controls.mode_of_payment.value == 'mode_of_payment_cheque' ? true : false,
        "mode_of_payment_netbanking": this.form.controls.mode_of_payment.value == 'mode_of_payment_netbanking' ? true : false,
        "mode_of_payment_paytm": this.form.controls.mode_of_payment.value == 'mode_of_payment_paytm' ? true : false,
        "mode_of_payment_other": this.form.controls.mode_of_payment.value == 'mode_of_payment_other' ? true : false,
        "suggestion": this.form.controls.suggestion.value,
        "PAN_number": this.form.controls.PAN_number.value,
        "membership_contribution_monthly": this.form.controls.membership_contribution.value == 'membership_contribution_monthly' ? true : false,
        "membership_contribution_quarterly": this.form.controls.membership_contribution.value == 'membership_contribution_quarterly' ? true : false,
        "membership_contribution_half_yearly": this.form.controls.membership_contribution.value == 'membership_contribution_half_yearly' ? true : false,
        "membership_contribution_yearly": this.form.controls.membership_contribution.value == 'membership_contribution_yearly' ? true : false,
        "membership_contribution_other": this.form.controls.membership_contribution.value == 'membership_contribution_other' ? true : false,
        "contribute_monthly_amount": this.form.controls.contribute_monthly_amount.value,
        "activate": this.form.controls.activate.value,
        // "image": this.imageUpload
      }
      // console.log(data);
            
      if(this.update_item){
        data['membership_id']= this.update_item['membership_id'];
        this.coreService.put('updatemembership', data).subscribe((data: any) => {
          this.submitted = false;
          this.toastr.success('Success', 'Membership updated Successfully');
          this.getAllItem(0);
          this.form.reset()   
        }, (error) => {
          console.log(error)
          // this.toastr.error(error.statusText);
        });
      }else{
        this.coreService.post('createMember', data).subscribe((data: any) => {
          this.submitted = false;
          this.toastr.success('Success', 'Membership created Successfully');
          this.getAllItem(0);
          this.form.reset()   
        }, (error) => {
          console.log(error)
          // this.toastr.error(error.statusText);
        });
      }
      return true;
    }
    
  }

  deleteItem(item){
    this.coreService.delete('deleteMember?member_profile_id='+item.member_id).subscribe((data: any) => {
      this.submitted = false;
      this.toastr.success('Success', 'Member deleted Successfully');
      this.getAllItem(0);
    }, (error) => {
      console.log(error)
      // this.toastr.error(error.statusText);
    });
  }
  editItem(item){
    this.update_item = item;
    this.form.controls['member_type'].setValue(item.member_type);
    this.form.controls['name'].setValue(item.name);
    this.form.controls['dob'].setValue(item.dob);
    this.form.controls['mobile_number'].setValue(item.mobile_number);
    this.form.controls['email'].setValue(item.email);
    this.form.controls['address'].setValue(item.address);

    this.form.controls['city'].setValue(item.city);
    this.form.controls['state'].setValue(item.state);
    this.form.controls['zipcode'].setValue(item.zipcode);
    this.form.controls['Aadhar_number'].setValue(item.Aadhar_number);
    this.form.controls['Educational_Qualification'].setValue(item.Educational_Qualification);
    this.form.controls['profession'].setValue(item.profession);

    this.form.controls['ngo_member'].setValue(item.ngo_member);
    this.form.controls['amount_pay_yes'].setValue(item.amount_pay_yes);


    if(item.amount_pay == 'amount_2100'){
      this.form.controls['amount_pay'].setValue('amount_2100');
    }else if(item.amount_pay == 'amount_3100'){
      this.form.controls['amount_pay'].setValue('amount_3100');
    }else if(item.amount_pay == 'amount_5100'){
      this.form.controls['amount_pay'].setValue('amount_5100');
    }else if(item.amount_pay == 'amount_10000'){
      this.form.controls['amount_pay'].setValue('amount_10000');
    }else if(item.amount_pay == 'other_amount'){
      this.form.controls['amount_pay'].setValue('other_amount');
    }
        
        
    this.form.controls['activate'].setValue(item.activate);
    this.form.controls['contribute_monthly_amount'].setValue(item.contribute_monthly_amount);
    this.form.controls['PAN_number'].setValue(item.PAN_number);
    this.form.controls['suggestion'].setValue(item.suggestion);
    this.form.controls['amount'].setValue(item.amount);
    this.form.controls['other_contribution'].setValue(item.other_contribution);
    // this.imageUpload = item.image
  }

  handleFileInput(event, type) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e:any) => {   
            this.imageUpload = e.currentTarget['result'];   
        
      };
    }
  }
}
