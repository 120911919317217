import { Component, OnInit } from "@angular/core";
import * as jQuery from 'jquery';

declare var $;

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ['./gallery.component.css']  
})
export class GalleryComponent implements OnInit {
  constructor() {}
  gallery = [{
    image:"assets/images/photos/65229876_408200799780304_2433531145678225408_n.jpg",   
  },{
  image:"assets/images/photos/64950754_408197633113954_826812523381522432_n.jpg",
  },{
  image:"assets/images/photos/65047448_408197636447287_2185442702106034176_n.jpg",
  },

  {
    image:"assets/images/photos/65019298_408200806446970_5655764719517040640_n.jpg",   
  },{
  image:"assets/images/photos/244611404_915144369085942_7411723890733985900_n.jpg",
  },{
  image:"assets/images/photos/251493236_934938067106572_6516831420252637736_n.jpg",
  },

  {
    image:"assets/images/photos/258077112_940579159875796_4309159564227322167_n.jpg",   
  },{
  image:"assets/images/photos/245175004_918560068744372_8306900067320578388_n.jpg",
  },{
  image:"assets/images/photos/252937893_934124923854553_8722365835029431458_n.jpg",
  },


  {
    image:"assets/images/photos/65390316_408198356447215_4503409209995427840_n.jpg",   
  },{
  image:"assets/images/photos/55560646_366121527321565_1874214803308806144_n.jpg",
  },{
  image:"assets/images/photos/245063921_918560432077669_4673679943058163798_n.jpg",
  }
]
  ngOnInit(): void {}

  // navigate(templateId) {
  //   // this.loading = true;
  //   //loadingStart("Please wait...");

  //   setTimeout(() => {
  //     // this.loading = false;
  //     // loadingStop();
  //   }, 2000);

  //   console.log(templateId);
  //   let nav = $(templateId);
  //   let value = nav.offset().top - 180;
  //   if (templateId.includes("donate")) {
  //     value = nav.offset().top + 180;
  //   }

  //   //alert(value);
  //   jQuery("html, body").animate(
  //     {
  //       scrollTop: value,
  //     },
  //     200,
  //     "swing",
  //     function () {
  //       // window.location.hash = target
  //     }
  //   );
  // }
}
