import { Component, OnInit,OnChanges } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import * as jQuery from 'jquery';
import {  ViewChild, ElementRef } from '@angular/core';
declare var $;

@Component({
  selector: "app-what-we-do",
  templateUrl: "./what-we-do.component.html",
  styleUrls: ['./what-we-do.component.css']
})
export class WhatWeDoComponent implements OnInit, OnChanges {
  constructor(private router:Router) {}
  
  blogsdata: any = [];
  question= [{},{},{},{}]
  ngOnInit(): void {
   
    this.initializedBlogs();
   
  }
  ngOnChanges(): void {
    this.initializedBlogs()
  }

  async initializedBlogs() {
    let blogsdata: any = [
      {
        image: "/assets/img/whatwedoimg1.png",
        title: "Child Education",
        description: `
        DBN has been working with the under privileged children to provide them education opportunities. We are working on several social development projects in slums and low income community areas with an aim to give them a happy life.
 
        To break the cycle of poverty with a vision of a world where everyone has equal access to resources and can live full and dignified lives.`,
        link:'/child-education'
      },
      {
        image: "/assets/img/whatwedoimg2.png",
        title: "Computer Education",
        description: `
        DBN Initiated a collaborative internship training program in Information Technology for underprivileged students. DBN Initiated a collaborative internship training program in Information Technology for students of Govt. 

        Sarvodaya kanya vidalaya A block Janakpuri.`,
        link:'/computer-education'
      }, {
        image: "/assets/img/whatwedoimg3.png",
        title: "Women Empowerment",
        description: `
        DBN has set up a “Divine Silai Training Centre” to  give them opportunity to learn tailoring skill and  earn livelihood through this programme making women self-sufficient, confident and financially  independent.  

        The course duration is  3 and 6 months for basic cutting & sewing, with the participants progressing to the next level of training in art & creativity.`,
        link:'/divine-silai'
      }, {
        image: "/assets/img/whatwedoimg4.png",
        title: "Neki Ka Bazaar",
        description: `
        NEKI KA BAZAAR under which we have *set up Stalls at our NGO centre B2B/306 Janakpuri to help the needy by providing them with clothes, shoes, toys, books Stationeries free of cost throughout the year.

        Under this initiative, needy people may visit the centre and may take 2 jodi clothes n other stuff for themselves free of cost.`,
        link:'/neki-ka-bazaar'
      }, {
        image: "/assets/img/whatwedoimg5.png",
        title: "Divine Beautician Training Centre",
        description: `
        DIVINE BEAUTY CENTRE IMPART 3 MONTHS/6 MONTH TRAINING COURSE TO 
        SOCIALLY AND ECONOMICALLY WEAKER YOUNG GIRLS AND WOMEN.  
        
        WOMEN LEARN SEVERAL BEAUTY TECHNIQUES LIKE EYE BROW MAKING, FACIAL, MAKE-UP, MANICURE, PEDICURE, MEHNDI, HAIR CUT, WAX ETC.`,
        link:'/beautician'
      }, {
        image: "/assets/img/whatwedoimg6.png",
        title: "Divine Mehndi Training Centre",
        description: `
        With divine grace Mehendi Event on Karwachauth Organised by Divine Basic Needs NGO under its "Women Empowerment Project" at '7 different locations in West Delhi" was beautifully accomplished. 

        Our creative underprivileged girls amazed everyone by their mehendi creativity.`,
        link:'/divine-silai'
      },{
        image: "/assets/img/whatwedoimg7.png",
        title: "CLOTHES/Blankets DONATE",
        description: `
        Divine Basic Needs NGO distributed multiple thousands of clothes at Kirby Place Slums. Clothes donated by all of you were sorted at our NGO centre...packed according to different age groups/Gender etc and distributed to kids..ladies.. gents..oldies...Thanks to all kind hearted souls who were part of our clothes donation event...

We brought back so many Smiles and blessings for u all..`,
link:'/donate-blankets'
      },{
        image: "/assets/img/whatwedoimg8.png",
        title: "Feed The Hungry",
        description: `
        Round the year, we conduct multiples of food donation drives  as per request of our donors.  You can also join us during the food distribution drive.  Please feel  free to get in touch with our team to conduct a food donation drive on your special occasions. Distributed Rice, Wheat, Pulses, Spices, cooking Oil, fast foods like Maggie, Sugar, and Tea etc. as Dry Ration Packages.`,
        link:'/feed-poor'
      }
    ]
    let data: any = []
    console.log(blogsdata)
    for (let i = 0; i < blogsdata.length; i++) {
      blogsdata[i].description = await this.formateText(blogsdata[i].description)
      data.push(blogsdata[i])
      if (i == blogsdata.length - 1) {
        this.blogsdata = []
        this.blogsdata = data
        console.log(this.blogsdata)
      }

    }
  }
  formateText(description: any) {
    if (description) {
      return `${description.substring(0, 203)}..`
    }
    return '';
  }
  gotoLinkDonate(value){
    this.router.navigate(['/donations'], { queryParams: { type: value } });
  }
  // navigate(templateId) {
  //   // this.loading = true;
  //   //loadingStart("Please wait...");

  //   setTimeout(() => {
  //     // this.loading = false;
  //     // loadingStop();
  //   }, 2000);

  //   console.log(templateId);
  //   let nav = $(templateId);
  //   let value = nav.offset().top - 180;
  //   if (templateId.includes("donate")) {
  //     value = nav.offset().top + 180;
  //   }

  //   //alert(value);
  //   jQuery("html, body").animate(
  //     {
  //       scrollTop: value,
  //     },
  //     200,
  //     "swing",
  //     function () {
  //       // window.location.hash = target
  //     }
  //   );
  // }
  open(id){
    id =  ""+ id +"";
    var x:any = document.getElementById(id);
    var contentId =  "#"+ id +"";
    if (x.style.display == "none") {
      $(contentId).show(); 
      var containerClass =  "."+ id +"";
      $(containerClass).addClass("open-list-group-item");
      let iconClass = "" + containerClass + ' .fa-chevron-down' + "" ;
      $(iconClass).addClass("fa-chevron-up")
    } else {
      $(contentId).hide(); 
      var containerClass =  "."+ id +"";
      $(containerClass).removeClass("open-list-group-item");
      let iconClass = "" + containerClass + ' .fa-chevron-down' + "" ;
      $(iconClass).removeClass("fa-chevron-up")
    } 
  }
}
