import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';



@Injectable()

export class CoreService {
  constructor(private http: HttpClient,@Inject(PLATFORM_ID) private platformId: object) { }
  

  get(url,options?) {
    return this.http.get<any>(url,options);
  }

  post(url, object) {
    return this.http.post(url, object);
  }

  put(url, object) {
    return this.http.put(url, object);
  }

  delete(url) {
    return this.http.delete(url);
  }
  postFile(url,fileToUpload:FormData) {
    return this.http.post(url, fileToUpload)
    }
  get nativeWindow(): any {
    if (isPlatformBrowser(this.platformId)) {
      return this._window();
    }
  }

   _window(): any {
    // return the global native browser window object
    return window;
  }

}
