import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-volunteer",
  templateUrl: "./volunteer.component.html",
})
export class VolunteerComponent implements OnInit {
  constructor() {}
  volunteer = [{
    image:"/assets/images/volunteers/neerja.jpg",
    name:"Dr. Neerja Wadhwa",
    join:"Founder President",
    description:"Lorem ipsum dolor sit amet, con amit sectetur adipisicing"
},{
  image:"/assets/images/volunteers/asha.jpg",
  name:"Mrs. Asha Sethi  ",
  join:"Vice-President/Trustee",
  description:"Lorem ipsum dolor sit amet, con amit sectetur adipisicing"
},{
  image:"/assets/images/volunteers/trun.jpg",
  name:"Mr. Tarun Kumar Chauhan ",
    join:"General Secretary/ Trustee",
    description:"Lorem ipsum dolor sit amet, con amit sectetur adipisicing"
}
,{
  image:"/assets/images/volunteers/diwakar.jpg",
  name:"Mr. Diwakar Baluni  ",
    join:"Joint Secretary/ Trustee",
    description:"Lorem ipsum dolor sit amet, con amit sectetur adipisicing"
},{
  image:"/assets/images/volunteers/shashi.jpg",
  name:"Mrs. Shashi Kala Handoo ",
    join:"Treasurer/ Trustee",
    description:"Lorem ipsum dolor sit amet, con amit sectetur adipisicing"
}

,{
  image:"/assets/images/volunteers/neelam.jpg",
  name:" Mrs. Neelam Vaid ",
    join:"Program Director/ Trustee",
    description:"Lorem ipsum dolor sit amet, con amit sectetur adipisicing"
}
,{
  image:"/assets/images/volunteers/asok.jpg",
  name:"Mr. Ashok Kumar Garg",
    join:"Advisor/ Trustee",
    description:"Lorem ipsum dolor sit amet, con amit sectetur adipisicing"
},{
  image:"/assets/images/volunteers/rajni.jpg",
  name:"Mrs. Rajni Parmar ",
    join:"Administrative  Manager/ Trustee",
    description:"Lorem ipsum dolor sit amet, con amit sectetur adipisicing"
}]


  ngOnInit(): void {}
}
