import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/shared/guard/auth.service";
import { CoreService } from "src/app/shared/guard/core.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
})
export class ContactComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  constructor(public coreService: CoreService, private router: Router , 
    private toastr: ToastrService,public authService : AuthService) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm(){
    this.form = new FormGroup({      
      "name": new FormControl('', [Validators.required]),
      "email": new FormControl('', [Validators.required]),
      "subject": new FormControl('', [Validators.required]),
      "phone": new FormControl('', [Validators.required]),
      "message": new FormControl('', [Validators.required])
    });
  }
  get f(){
    return this.form.controls; 
  }
  submit(){
    
    this.submitted = true;
    console.log(this.form)
    if (this.form.invalid) {
      return false;
    } else {
      this.submitted = true;

      let data = {
        // username: this.form.controls.userName.value,
        // password: this.form.controls.password.value,
        "name": this.form.controls.name.value,
        "email": this.form.controls.email.value,
        "subject": this.form.controls.subject.value,
        "phone": this.form.controls.phone.value,
        "message": this.form.controls.message.value
      }
      this.coreService.post('contactUs', data).subscribe((data: any) => {
        this.submitted = false;
        this.toastr.success('Success', 'Your request has sent to our team, they will contact you soon');
        this.form.reset()
   
      }, (error) => {
        console.log(error)
        // this.toastr.error(error.statusText);
      });

    }
    return true;
  }
}
