import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CoreService } from "src/app/shared/guard/core.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  constructor(public router : Router, public coreService : CoreService,private toastr: ToastrService) {}
  ngOnInit(): void {
    this.createForm()
  }


  createForm(){
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      first_name: new FormControl('', [Validators.required]),
      lasaott_name: new FormControl('', [Validators.required]),
      email_address: new FormControl('', [Validators.required]),
      country_code: new FormControl('91', [Validators.required]),
      mobile_number: new FormControl('', [Validators.required]),
      phone_number: new FormControl('', [Validators.required]),
      dob: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      zipcode: new FormControl('', [Validators.required]),
      image: new FormControl(''),
    });
  }

  get f(){
    return this.form.controls; 
  }
  
  setUserName(value){
    this.form.controls.username.reset(value)
  }

  submit() {console.log("inside the method")
    this.submitted = true;
    console.log(this.form)
    if (this.form.invalid) {
      return;
    } else {
      console.log(this.form.value)
      this.coreService.post('signUp', this.form.value).subscribe((data: any) => {
        if (data.code == 202 || data.code == 201 || data.code == 200) {
          this.submitted = false
          this.toastr.success('Success', 'User Registered successfully');

          // this.toastrService.success('Success', "Patient Added successfully");
          this.router.navigate(['/auth/login'])
        }
      })
    }
  }
}



