import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/shared/guard/auth.service";
import { CoreService } from "src/app/shared/guard/core.service";

@Component({
  selector: "app-admin-internship",
  templateUrl: "./admin-internship.component.html",
  styleUrls: ["./admin-internship.component.css"]
})
export class AdminInternshipComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  internship:any = [];
  update_item : any;
  imageUpload = '';
  isShowList = true;
  constructor(public coreService: CoreService, private router: Router , 
    private toastr: ToastrService,public authService : AuthService) {}

  ngOnInit(): void {
    this.getAllItem();
    this.createForm();
  }

  createForm(){
    this.form = new FormGroup({      
      "first_name": new FormControl('', [Validators.required]),
      "last_name": new FormControl('', [Validators.required]),
      "dob": new FormControl('', [Validators.required]),
      "gender": new FormControl('', [Validators.required]),
      "email": new FormControl('', [Validators.required]),
      "alternate_email": new FormControl('', [Validators.required]),
      "mobile_number": new FormControl('', [Validators.required]),
      "alternate_number": new FormControl('', [Validators.required]),
      "currently_residing": new FormControl('', [Validators.required]),
      "current_state": new FormControl('', [Validators.required]),
      "current_professional_status": new FormControl('', [Validators.required]),
      "current_employer": new FormControl('', [Validators.required]),
      "prior_work_exp": new FormControl('', [Validators.required]),
      "most_recent_employer": new FormControl('', [Validators.required]),
      "highest_education": new FormControl('', [Validators.required]),
      "undergraduate_degree": new FormControl('', [Validators.required]),
      "undergraduate_stream": new FormControl('', [Validators.required]),
      "undergraduate_college": new FormControl('', [Validators.required]),
      "undergraduate_completion_date": new FormControl('', [Validators.required]),
      "undergraduate_percentage": new FormControl('', [Validators.required]),
      "postgraduate_degree": new FormControl('', [Validators.required]),
      "postgraduate_stream": new FormControl('', [Validators.required]),
      "postgraduate_college": new FormControl('', [Validators.required]),
      "postgraduate_completion_date": new FormControl('', [Validators.required]),
      "postgraduate_percentage": new FormControl('', [Validators.required]),
      "other_additional_qualification": new FormControl('', [Validators.required]),
      "inter_board_certification": new FormControl('', [Validators.required]),
      "inter_completion_date": new FormControl('', [Validators.required]),
      "inter_percentage": new FormControl('', [Validators.required]),
      "hear_dbn_ngo": new FormControl('', [Validators.required]),
      // "image": new FormControl(''),
    });
  }
  get f(){
    return this.form.controls; 
  }

  getAllItem(){
    this.coreService.get('getEventList?page_number=10&page_size=10&search=').subscribe((data: any) => {
      this.internship = data.details;
    }, (error) => {
      console.log(error)
      // this.toastr.error(error.statusText);
    });
  }
  submit(){
    
    this.submitted = true;
    console.log(this.form)
    if (this.form.invalid) {
      return false;
    } else {
      this.submitted = true;

      let data = {
        "first_name": this.form.controls.first_name.value,
        "last_name": this.form.controls.last_name.value,
        "dob": this.form.controls.dob.value,
        "gender": this.form.controls.gender.value,
        "email": this.form.controls.email.value,
        "alternate_email": this.form.controls.alternate_email.value,
        "mobile_number": this.form.controls.mobile_number.value,
        "alternate_number": this.form.controls.alternate_number.value,
        "currently_residing": this.form.controls.currently_residing.value,
        "current_state": this.form.controls.current_state.value,
        "current_professional_status": this.form.controls.current_professional_status.value,
        "current_employer": this.form.controls.current_employer.value,
        "prior_work_exp": this.form.controls.prior_work_exp.value,
        "most_recent_employer": this.form.controls.most_recent_employer.value,
        "highest_education": this.form.controls.highest_education.value,
        "undergraduate_degree": this.form.controls.undergraduate_degree.value,
        "undergraduate_stream": this.form.controls.undergraduate_stream.value,

        "undergraduate_college": this.form.controls.undergraduate_college.value,
        "undergraduate_completion_date": this.form.controls.undergraduate_completion_date.value,
        "undergraduate_percentage": this.form.controls.undergraduate_percentage.value,
        "postgraduate_degree": this.form.controls.postgraduate_degree.value,
        "postgraduate_stream": this.form.controls.postgraduate_stream.value,
        "postgraduate_college": this.form.controls.postgraduate_college.value,
        "postgraduate_completion_date": this.form.controls.postgraduate_completion_date.value,
        "postgraduate_percentage": this.form.controls.postgraduate_percentage.value,
        "other_additional_qualification": this.form.controls.other_additional_qualification.value,
        "inter_board_certification": this.form.controls.inter_board_certification.value,
        "inter_completion_date": this.form.controls.inter_completion_date.value,
        "inter_percentage": this.form.controls.inter_percentage.value,
        "hear_dbn_ngo": this.form.controls.hear_dbn_ngo.value,
        // "image": this.imageUpload
      }
      console.log(data);
            
      if(this.update_item){
        data['internship_id']= this.update_item['internship_id'];
        this.coreService.put('updateinternship', data).subscribe((data: any) => {
          this.submitted = false;
          this.toastr.success('Success', 'Internship updated Successfully');
          this.getAllItem();
          this.form.reset()   
        }, (error) => {
          console.log(error)
          // this.toastr.error(error.statusText);
        });
      }else{
        this.coreService.post('createInternship', data).subscribe((data: any) => {
          this.submitted = false;
          this.toastr.success('Success', 'Internship created Successfully');
          this.getAllItem();
          this.form.reset()   
        }, (error) => {
          console.log(error)
          // this.toastr.error(error.statusText);
        });
      }
      return true;
    }
    
  }

  deleteItem(item){
    this.coreService.delete('deleteProject?project_id='+item.project_id).subscribe((data: any) => {
      this.submitted = false;
      this.toastr.success('Success', 'einternshipvents deleted Successfully');
      this.getAllItem();
    }, (error) => {
      console.log(error)
      // this.toastr.error(error.statusText);
    });
  }
  editItem(item){
    this.update_item = item;
    this.form.controls['title'].setValue(item.title);
    this.form.controls['description'].setValue(item.description);
    this.form.controls['target_amount'].setValue(item.target_amount);
    this.form.controls['fund_received'].setValue(item.fund_received);
    this.form.controls['deadline'].setValue(item.deadline);
    this.form.controls['image'].setValue(item.image);
    // this.imageUpload = item.image
  }

  handleFileInput(event, type) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e:any) => {   
            this.imageUpload = e.currentTarget['result'];   
        
      };
    }
  }
}
