import { Component, OnInit } from "@angular/core";
import * as jQuery from 'jquery';

declare var $;

@Component({
  selector: "app-blogs",
  templateUrl: "./blogs.component.html",
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {
  constructor() {}
 
  testing:any=[
    {
      image: "assets/img/Rectangle 196.png",
      title: "Donate A Blanket. Save A Life",
      subtitle:` Every winter, DBN runs a Blanket Donation Campaign, where we provide high quality warm blankets along with dry ration and dignity kits to the underprivileged living in the open on the streets.`,
      content: `We try our best to reach out to people in Delhi NCR and other parts of north India. Donate a warm blanket and save the life of the homeless.`,
      comment_img:"assets/img/comments.svg",
      comment_number:26,
      heart_img:"assets/img/heart.svg",
      heart_number:50,
      date_text:`Feb 12, 2023  •  1 min read`
    },
    {
      image: "assets/img/Rectangle 192.png",
      title: "Donate Hygiene and Dignity Kit for Women",
      subtitle:` We at DBN have witnessed these struggles homeless women go through with close quarters. While efforts are being taken to provide safe shelters to this section, we cannot shy away from our responsibilities. Let us strive to improve the lives of homeless women by creating a safe and secure environment for them.`,
      content:``,
      comment_img:"assets/img/comments.svg",
      comment_number:26,
      heart_img:"assets/img/heart.svg",
      heart_number:50,
      date_text:`Jan 06, 2023  •  2 min readd`
    },
    {
      image: "assets/img/Rectangle 195.png",
      title: "Donate Educational Kit for Children",
      subtitle:` We do get a lot of support for children around children’s day. We feel every day is a children’s day. It’s time we who are educated, engage with homeless children and support their education.`,
      content: `It is our responsibility to ensure a bright and promising future for our next generation. Let’s join hands for a brighter future.`,
      comment_img:"assets/img/comments.svg",
      comment_number:26,
      heart_img:"assets/img/heart.svg",
      heart_number:50,
      date_text:`Dec 23, 2022  •  5 min read`
    },
    {
      image: "assets/img/Rectangle 200.png",
      title: "Donate Your Old Clothes",
      subtitle:`Our Clothes donation drive is exclusively meant for the children and adults in the hospitals, children living near railway tracks, under flyovers, children of daily wagers at construction sites, under- privileged children in Govt. Hospitals and to some of our city’s most in-need populations.`,
      content: `Please consider donating your old clothes to us.`,
      comment_img:"assets/img/comments.svg",
      comment_number:26,
      heart_img:"assets/img/heart.svg",
      heart_number:50,
      date_text:`Nov 28, 2022  •  10 min read`
    },
    {
      image: "assets/img/Rectangle 190.png",
      title: "Donate food on Death Anniversary of Loved Ones",
      subtitle:`DBN will help you to conduct food donation drives on the death anniversary of your near and dear ones.`,
      content: ` Round the year, we conduct thousands of food donation drives on death anniversaries as per request of our donors.`,
      comment_img:"assets/img/comments.svg",
      comment_number:26,
      heart_img:"assets/img/heart.svg",
      heart_number:50,
      date_text:`Oct 30, 2022  •  11 min read`
    },
    {
      image: "assets/img/Rectangle 196 (1).png",
      title: "Help us to Provide Food to the Hungry",
      subtitle:` Majority of the underprivileged patients admitted in Government hospitals are those who come from nearby states are often poor, in several cases family members decide to stay hungry to save money. Join the DBN to provide food to poor patients and their families outside hospitals. 
      With just Rs.550/- you can provide food to 50 people.`,
      content: ``,
      comment_img:"assets/img/comments.svg",
      comment_number:26,
      heart_img:"assets/img/heart.svg",
      heart_number:50,
      date_text:`Sep 19, 2022  •  16 min read`
    },
   
    
  ]
 
  ngOnInit(): void {

  }
  // navigate(templateId) {
  //   // this.loading = true;
  //   //loadingStart("Please wait...");

  //   setTimeout(() => {
  //     // this.loading = false;
  //     // loadingStop();
  //   }, 2000);

  //   console.log(templateId);
  //   let nav = $(templateId);
  //   let value = nav.offset().top - 180;
  //   if (templateId.includes("donate")) {
  //     value = nav.offset().top + 180;
  //   }

  //   //alert(value);
  //   jQuery("html, body").animate(
  //     {
  //       scrollTop: value,
  //     },
  //     200,
  //     "swing",
  //     function () {
  //       // window.location.hash = target
  //     }
  //   );
  // }
  open(id){
    id =  ""+ id +"";
    var x:any = document.getElementById(id);
    var contentId =  "#"+ id +"";
    if (x.style.display == "none") {
      $(contentId).show(); 
      var containerClass =  "."+ id +"";
      $(containerClass).addClass("open-list-group-item");
      let iconClass = "" + containerClass + ' .fa-chevron-down' + "" ;
      $(iconClass).addClass("fa-chevron-up")
    } else {
      $(contentId).hide(); 
      var containerClass =  "."+ id +"";
      $(containerClass).removeClass("open-list-group-item");
      let iconClass = "" + containerClass + ' .fa-chevron-down' + "" ;
      $(iconClass).removeClass("fa-chevron-up")
    } 
  }
}
