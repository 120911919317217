import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/shared/guard/auth.service";
import { CoreService } from "src/app/shared/guard/core.service";

@Component({
  selector: "app-admin-volunteer",
  templateUrl: "./admin-volunteer.component.html",
})
export class AdminVolunteerComponent implements OnInit {
  
  project:any = [];
  
  constructor(public coreService: CoreService, private router: Router , 
    private toastr: ToastrService,public authService : AuthService) {}

  ngOnInit(): void {
    this.getAllItem();
  
  }

  
  getAllItem(){
    this.coreService.get('getVolunteerList?page_number=1&page_size=1000').subscribe((data: any) => {
      this.project = data.details;
    }, (error) => {
      console.log(error)
      // this.toastr.error(error.statusText);
    });
  }
  
  
}
