import { Component, OnInit } from "@angular/core";
import * as jQuery from 'jquery';
import {  ViewChild, ElementRef } from '@angular/core';
declare var $;

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  constructor() {}
  question= [{},{},{},{}]
  ngOnInit() {
    this.initializeSliders();
  }
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  // isPlaying: boolean = false;
  // showButton = true;
  // toggleVideo() {
  //   if (this.isPlaying) {
  //     this.videoPlayer.nativeElement.pause();
  //     this.showButton = true;
  //   } else {
  //     this.videoPlayer.nativeElement.play();
  //     this.showButton = false;
  //   }
  //   this.isPlaying = !this.isPlaying;
  // }
  initializeSliders(){
    $(" .fullwidth-carousel").each(function () {
      var data_dots =
        $(this).data("dots") === undefined ? false : $(this).data("dots");
      var data_nav =
        $(this).data("nav") === undefined ? false : $(this).data("nav");
      var data_duration =
        $(this).data("duration") === undefined
          ? 4000
          : $(this).data("duration");
      $(this).owlCarousel({
        // rtl: THEMEMASCOT.isRTL.check(),
        autoplay: true,
        autoplayTimeout: data_duration,
        loop: true,
        items: 1,
        // dots: data_dots,
        // nav: data_nav,
        dots: false,
        nav: true,
        navText: [
          '<img src="assets/imgs/→ (2).png"/ style="position:absolute;left:-27px">',
          '<img src="assets/imgs/arrow-right.svg"/>',
        ],
      });
    });
  }
  
  // navigate(templateId) {
  //   // this.loading = true;
  //   //loadingStart("Please wait...");

  //   setTimeout(() => {
  //     // this.loading = false;
  //     // loadingStop();
  //   }, 2000);

  //   console.log(templateId);
  //   let nav = $(templateId);
  //   let value = nav.offset().top - 180;
  //   if (templateId.includes("donate")) {
  //     value = nav.offset().top + 180;
  //   }

  //   //alert(value);
  //   jQuery("html, body").animate(
  //     {
  //       scrollTop: value,
  //     },
  //     200,
  //     "swing",
  //     function () {
  //       // window.location.hash = target
  //     }
  //   );
  // }

  open(id){
    id =  ""+ id +"";
    var x:any = document.getElementById(id);
    var contentId =  "#"+ id +"";
    if (x.style.display == "none") {
      $(contentId).show(); 
      var containerClass =  "."+ id +"";
      $(containerClass).addClass("open-list-group-item");
      let iconClass = "" + containerClass + ' .fa-chevron-down' + "" ;
      $(iconClass).addClass("fa-chevron-up")
    } else {
      $(contentId).hide(); 
      var containerClass =  "."+ id +"";
      $(containerClass).removeClass("open-list-group-item");
      let iconClass = "" + containerClass + ' .fa-chevron-down' + "" ;
      $(iconClass).removeClass("fa-chevron-up")
    } 
  }
}
