import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/guard/auth.service';
import { CoreService } from 'src/app/shared/guard/core.service';
import { LoadingService } from 'src/app/shared/loading.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {
  @Output() navigateTOId = new EventEmitter();
  userDetail : any;
  activePathName = '';
  constructor(public coreService: CoreService, public authService : AuthService,
    private _loading: LoadingService,
    public router : Router) { 
    this.activePathName =  window.location.pathname;
    // console.log(window.location.pathname)
  }

  ngOnInit(): void {
   let user_info = localStorage.getItem('user_info');
   if(user_info){
    this.userDetail = JSON.parse(user_info);
   }
  //  this.getUserInfo();;
  }

  getUserInfo(){
    let url = 'getCurrentUser';
    // this._loading.setLoading(true, url);
    this.coreService.get(url).subscribe((data: any) => {
      // this._loading.setLoading(false, url);
      this.userDetail = data;
    }, (error) => {
      console.log(error)
    });
  }
  navigate(templateId){
    this.navigateTOId.emit(templateId);
  }
  logout(){
    this.authService.clearLocalStorage();
    this.router.navigate(['/dashboard'])

  }
}
