import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";
import { AdminBlogsComponent } from "./views/admin/admin-blogs/admin-blogs.component";
import { AdminDashboardComponent } from "./views/admin/admin-dashboard/admin-dashboard.component";
import { AdminEventComponent } from "./views/admin/admin-event/admin-event.component";
import { AdminEventsComponent } from "./views/admin/admin-events/admin-events.component";
import { AdminInternshipComponent } from "./views/admin/admin-internship/admin-internship.component";
import { AdminProjectComponent } from "./views/admin/admin-project/admin-project.component";
import { AdminMembershipComponent } from "./views/admin/admin-membership/admin-membership.component";
import { AdminUsersComponent } from "./views/admin/admin-users/admin-users.component";
import { AdminVolunteerComponent } from "./views/admin/admin-volunteer/admin-volunteer.component";
import { BlogDetailsComponent } from "./views/admin/blog-details/blog-details.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { ContactUsComponent } from "./views/admin/contact-us/contact-us.component";
import { DonationComponent } from "./donation/donation.component";
import { DisclaimerComponent } from "./disclaimer/disclaimer.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";
import { AdminFeedbackComponent } from "./views/admin/admin-feedback/admin-feedback.component";
import { AboutComponent } from "./views/admin/about/about.component";
import { GetInvolvedComponent } from "./views/admin/get-involved/get-involved.component";
import { GalleryComponent } from "./views/admin/gallery/gallery.component";
import { WhatWeDoComponent } from "./views/admin/what-we-do/what-we-do.component";
import { BlogsComponent } from "./views/admin/Blogs/blogs.component";
import { DonationsComponent } from "./views/admin/donations/donations.component";
import { NekiKaBazaarComponent } from "./neki-ka-bazaar/neki-ka-bazaar.component";
import { BeauticianComponent } from "./beautician/beautician.component";
import { GurukulComponent } from "./gurukul/gurukul.component";
import { EducationalKitsComponent } from "./educational-kits/educational-kits.component";
import { DivineSilaiComponent } from "./divine-silai/divine-silai.component";
import { DonateBlanketsComponent } from "./views/admin/donate-blankets/donate-blankets.component";
import { FeedPoorComponent } from "./views/admin/feed-poor/feed-poor.component";
import { ComputerEducationComponent } from "./views/admin/computer-education/computer-education.component";
import { ChildEducationComponent } from "./views/admin/child-education/child-education.component";
import { AdultEducationComponent } from "./views/admin/adult-education/adult-education.component";
import { YogaMeditationComponent } from "./views/admin/yoga-meditation/yoga-meditation.component";


// no layouts views


const routes: Routes = [
  // auth views
  { path: "dashboard", component: DashboardComponent },
  { path: "about-us", component: AboutComponent },
  { path: "involved", component: GetInvolvedComponent},
  { path: "gallery", component: GalleryComponent},
  { path :"what-we-do" , component :WhatWeDoComponent},
  { path :"blogs" , component :BlogsComponent},
  { path :'donations', component:DonationsComponent },
  {path : 'blogs/:id', component:BlogDetailsComponent},
  { path : "admin-blog",component:AdminBlogsComponent},
  { path : "admin-dashboard", component : AdminDashboardComponent},
  { path : 'admin-event', component:AdminEventComponent},
  { path : 'admin-events', component:AdminEventsComponent},
  { path : 'admin-internship', component:AdminInternshipComponent},
  { path : 'admin-membership', component:AdminMembershipComponent},
  { path : 'admin-project', component : AdminProjectComponent},
  { path : 'admin-users' , component:AdminUsersComponent},
  { path : 'admin-volunteer' , component : AdminVolunteerComponent},
  { path : 'admin-feedback' , component : AdminFeedbackComponent},
  { path: 'blog' , component:BlogDetailsComponent},
  { path: 'contact-us', component:ContactUsComponent},
  {path : 'disclaimer', component:DisclaimerComponent},
{ path : 'privacy-policy', component:PrivacyPolicyComponent},
  { path : 'neki-ka-bazaar' , component: NekiKaBazaarComponent},
  { path : 'beautician', component : BeauticianComponent},
  { path : 'gurukul' , component : GurukulComponent},
  { path : 'educational-kits' , component:EducationalKitsComponent},
  { path : 'divine-silai', component:DivineSilaiComponent },
  { path : 'donate-blankets', component : DonateBlanketsComponent},
  { path : 'feed-poor', component:FeedPoorComponent},
  { path : 'computer-education', component:ComputerEducationComponent},
  {path:'child-education',component:ChildEducationComponent},
  {path : 'adult-education', component:AdultEducationComponent},
  {path: 'yoga-meditation', component:YogaMeditationComponent},
  // { path :'donation', component:DonationComponent },
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "register", component: RegisterComponent },
      
    ],
  },
  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  // admin views
  // {
  //   path: "admin",
  //   component: AdminComponent,
  //   children: [
  //     { path: "dashboard", component: DashboardComponent },
  //     { path: "settings", component: SettingsComponent },
      // { path: "tables", component: TablesComponent },
  //     { path: "maps", component: MapsComponent },
  //     { path: "", redirectTo: "dashboard", pathMatch: "full" },
  //   ],
  // },
  
  // // no layout views
  // { path: "profile", component: ProfileComponent },
  // { path: "landing", component: LandingComponent },
  // { path: "", component: IndexComponent },
  // { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
