import { Component, OnInit, Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { CoreService } from "src/app/shared/guard/core.service";
import { ViewportScroller } from "@angular/common";

import * as jQuery from "jquery";
import { LoadingService } from "src/app/shared/loading.service";
declare var $;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @Output() navigateTOId = new EventEmitter();
  userDetail: any = null;
  menuItem: number = 0;
  constructor(public coreService: CoreService, private route: ActivatedRoute,public router:Router,
    private _loading: LoadingService, private scroller: ViewportScroller) {
    // this.route.queryParamMap.subscribe((params) => {
    //   console.log(params);
    //   console.log(params["params"]);
    //   setTimeout(() => {
    //     this.navigate(`#${params["params"]["id"]}`);
    //   });
    // });
  }
  openModal() {
    $('#myModal').modal('show'); // Open Bootstrap modal using jQuery
  }
  registrationForm(){
    // $('#myModal').modal('hide'); 
    setTimeout(()=>{$('#registermodal').modal('show')},1500)
  }
  ngOnInit(): void {
    //  let user_info = localStorage.getItem('user_info');
    //  if(user_info){
    //   this.userDetail = JSON.parse(user_info);
    //  }
    // this.getUserInfo();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkForInternQueryParam();
      }
    });
  }
  private checkForInternQueryParam(): void {
    const queryParams = this.route.snapshot.queryParamMap;
    if (queryParams.has('intern')) {
      const involvedLink = document.querySelector('#involvedLink');
      if (involvedLink) {
        involvedLink.classList.add('active');
      }
      this.scroller.scrollToAnchor("intern");
    }
  }
  isInvolvedLinkActive(): boolean {
    return this.route.snapshot.queryParamMap.has('intern');
  }
  isDonationsRouteActive(): boolean {
    return this.router.url.startsWith('/donations');
  }

  getUserInfo() {
    let url ='getCurrentUser';
    // this._loading.setLoading(true, url);
    this.coreService.get(url).subscribe(
      (data: any) => {
        // this._loading.setLoading(false, url);
        this.userDetail = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  navigate(templateId) {
    console.log(templateId);
    // this.navigateTOId.emit(templateId);
  }

  ShowSubmenu(_id) {
    // $(".menuItem__custom").hide();
    // $("#menuItem_" + _id).show();
    //this.menuItem = _id;
  }
  HideSubMenu() {
    // $(".menuItem__custom").hide();
  }
}