import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/shared/guard/auth.service";
import { CoreService } from "src/app/shared/guard/core.service";
import { LoadingService } from "src/app/shared/loading.service";

@Component({
  selector: "app-admin-project",
  templateUrl: "./admin-project.component.html",
  styleUrls: ["./admin-project.component.css"]
})
export class AdminProjectComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  project:any = [];
  update_item : any;
  imageUpload = '';
  isShowList = true;
  constructor(public coreService: CoreService, private router: Router , 
    private _loading: LoadingService,
    private toastr: ToastrService,public authService : AuthService) {}

  ngOnInit(): void {
    // this.getAllItem();
    this.createForm();
  }

  createForm(){
    this.form = new FormGroup({      
      "title": new FormControl('', [Validators.required]),
      "description": new FormControl('', [Validators.required]),
      "target_amount": new FormControl(0, [Validators.required]),
      "fund_received": new FormControl(0, [Validators.required]),
      "deadline": new FormControl('', [Validators.required]),
      "image": new FormControl(''),
    });
  }
  get f(){
    return this.form.controls; 
  }

  getAllItem(){
    let url = 'getProjectList';
    this._loading.setLoading(true, url);
    this.coreService.get(url).subscribe((data: any) => {
      this._loading.setLoading(false, url);
      this.project = data.details;
    }, (error) => {
      console.log(error)
      // this.toastr.error(error.statusText);
    });
  }
  submit(){
    
    this.submitted = true;
    console.log(this.form)
    if (this.form.invalid) {
      return false;
    } else {
      this.submitted = true;

      let data = {
        "title": this.form.controls.title.value,
        "description": this.form.controls.description.value,
        "target_amount": this.form.controls.target_amount.value,
        "fund_received": this.form.controls.fund_received.value,
        "deadline": this.form.controls.deadline.value,
        "image": this.imageUpload
      }

      if(this.update_item){
        data['project_id']= this.update_item['project_id'];
        this.coreService.put('updateProject', data).subscribe((data: any) => {
          this.submitted = false;
          this.toastr.success('Success', 'Project updated Successfully');
          this.getAllItem();
          this.form.reset();
          this.isShowList = true;   
        }, (error) => {
          console.log(error)
          // this.toastr.error(error.statusText);
        });
      }else{
        this.coreService.post('createProject', data).subscribe((data: any) => {
          this.submitted = false;
          this.toastr.success('Success', 'Project created Successfully');
          this.getAllItem();
          this.form.reset()   
          this.isShowList = true;   
        }, (error) => {
          console.log(error)
          // this.toastr.error(error.statusText);
        });
      }
      return true;
    }
    
  }

  deleteItem(item){
    this.coreService.delete('deleteProject?project_id='+item.project_id).subscribe((data: any) => {
      this.submitted = false;
      this.toastr.success('Success', 'Project deleted Successfully');
      this.getAllItem();
    }, (error) => {
      console.log(error)
      // this.toastr.error(error.statusText);
    });
  }
  editItem(item){
    this.isShowList = true;   
    this.update_item = item;
    this.form.controls['title'].setValue(item.title);
    this.form.controls['description'].setValue(item.description);
    this.form.controls['target_amount'].setValue(item.target_amount);
    this.form.controls['fund_received'].setValue(item.fund_received);
    this.form.controls['deadline'].setValue(item.deadline);
    this.form.controls['image'].setValue(item.image);
    this.imageUpload = item.image
  }

  handleFileInput(event, type) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e:any) => {   
            this.imageUpload = e.currentTarget['result'];   
        
      };
    }
  }
}
