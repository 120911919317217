import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/shared/guard/auth.service";
import { CoreService } from "src/app/shared/guard/core.service";
import { LoadingService } from "src/app/shared/loading.service";
declare var $;

@Component({
  selector: "app-become-volunteer",
  templateUrl: "./become-volunteer.component.html",
  styleUrls: ["./become-volunteer.component.css"],
})
export class BecomeVolunteerComponent implements OnInit {
  form: FormGroup;
  file = "";
  volunteer:any = [];
  submitted: boolean = false;
  constructor(
    public coreService: CoreService,
    private router: Router,
    private toastr: ToastrService,
    public authService: AuthService,
    private _loading: LoadingService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getAllVolunteer();
  }

  createForm() {
    this.form = new FormGroup({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
      gender: new FormControl("", [Validators.required]),
      branch: new FormControl("", [Validators.required]),
      message: new FormControl("", [Validators.required]),
      file: new FormControl(""),
    });
  }
  get f() {
    return this.form.controls;
  }
  submit() {
    console.log("Submit");
    this.submitted = true;
    console.log(this.form);
    if (this.form.invalid) {
      return false;
    } else {
      this.submitted = true;

      let data = {
        // username: this.form.controls.userName.value,
        // password: this.form.controls.password.value,
        name: this.form.controls.name.value,
        email: this.form.controls.email.value,
        gender: Number(this.form.controls.gender.value),
        branch: Number(this.form.controls.branch.value),
        message: this.form.controls.message.value,
        file: this.file,
      };

      let form_data = new FormData();
      form_data.append("name", this.form.controls.name.value);
      form_data.append("email", this.form.controls.email.value);
      form_data.append("gender", this.form.controls.gender.value);
      form_data.append("branch", this.form.controls.branch.value);
      form_data.append("message", this.form.controls.message.value);
      form_data.append("file", this.file);

      console.log(form_data);
      let url = 'createVolunteer';
      this._loading.setLoading(true, url);
      this.coreService.post(url, form_data).subscribe(
        (data: any) => {
          this._loading.setLoading(false, url);
          this.submitted = false;
          this.toastr.success(
            "Success",
            "Your request has sent to our team, they will contact you soon"
          );
          this.form.reset();
        },
        (error) => {
          console.log(error);
          // this.toastr.error(error.statusText);
        }
      );
      return true;
    }
  }
  handleFileInput(event, type) {
    console.log(event.target.files);
    // if (event && event.target && event.target.files.length > 0) {
    //   const file = event.target.files[0];
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = (e:any) => {
    //     console.log(e)
    this.file = event.target.files.item(0);
    console.log(this.file);

    //   };
    // }
  }

  getAllVolunteer() {
    let url = 'getVolunteerList'
    this._loading.setLoading(true, url);
    this.coreService.get(url).subscribe(
      (data: any) => {
        this._loading.setLoading(false, url);
        //console.log(data.details);
        this.volunteer = data.details;
        setTimeout(() => {
          $(".owl-carousel-3col-volunteer").each(function () {
            var data_dots =
              $(this).data("dots") === undefined ? false : $(this).data("dots");
            var data_nav =
              $(this).data("nav") === undefined ? false : $(this).data("nav");
            var data_duration =
              $(this).data("duration") === undefined
                ? 4000
                : $(this).data("duration");
            $(this).owlCarousel({
              // rtl: THEMEMASCOT.isRTL.check(),
              autoplay: true,
              autoplayTimeout: data_duration,
              loop: true,
              items: 4,
              margin: 15,
              dots: data_dots,
              nav: data_nav,
              navText: [
                '<i class="pe-7s-angle-left"></i>',
                '<i class="pe-7s-angle-right"></i>',
              ],
              responsive: {
                0: {
                  items: 1,
                  center: false,
                },
                480: {
                  items: 1,
                  center: false,
                },
                600: {
                  items: 2,
                  center: false,
                },
                750: {
                  items: 3,
                  center: false,
                },
                960: {
                  items: 3,
                },
                1170: {
                  items: 4,
                },
                1300: {
                  items: 4,
                },
              },
            });
          });
        }, 200);
      },
      (error) => {
        console.log(error);
        // this.toastr.error(error.statusText);
      }
    );
  }
}
