import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as jQuery from "jquery";
import { CoreService } from "src/app/shared/guard/core.service";
import { LoadingService } from "src/app/shared/loading.service";
declare var $;

//import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-causes",
  templateUrl: "./causes.component.html",
})
export class CausesComponent implements OnInit {
  constructor(
    public coreService: CoreService,
    private _loading: LoadingService,
    private router: Router //private modalService: NgbModal
  ) {}
  causes:any = [];
  closeResult: string = "";

  showModal: boolean = false;
  content: any;
  title: any;
  openContentId: any;

  // [{
  //   description:"Provides non- formal basic education to non-school going and drop out children. We provide complete education support by fulfilling all their education related needs and requirements like complete tuition to school going children. ",
  //   title:"Child Education",
  //   image:'/assets/images/causes/repu20.jpg'
  // },{
  //   description:"Nations that invest in women's employment, health and education are just more likely to have better outcome. Their children will be healthier and better educated. This is not just the right thing to do for us to hold up those women, support them, encourage their involvement; this is a strategic imperative.",
  //   title:"Women Empowerment",
  //   image:'/assets/images/causes/certified.jpg'
  // },{
  //   description:"Round the year, we conduct multiples of food donation drives  as per request of our donors.  You can also join us during the food distribution drive.  Please feel  free to get in touch with our team to conduct a food donation drive on your special occasions.",
  //   title:"Basic Needs",
  //   image:'/assets/images/causes/BB24.jpg'
  // },{
  //   description:"Divine Basic Needs fulfills its most beautiful objective of spreading Smiles by organizing health and happiness workshops for kids and adults. Various Yoga and Meditations sessions are organized  from time to time for enhancing ones personality – physically, emotionally and spiritually.  Group Yoga and Meditation is practiced every early morning . If one is interested to join or organize any related worshops may write to us at divinebasicneeds@gmail.com",
  //   title:"Health, Yoga and Meditation",
  //   image:'/assets/images/causes/soap.jpg'
  // },{
  //   description:"Various awareness programmes in the form of workshops, talks, nukkad nataks, rallies  are organized from time to time to make  people aware about  importance of education,  environment,   health,  hygiene,  voting rights, safety etc.  You  can help us conduct such programmes as an individual, corporates, schools, colleges or institutions. ",
  //   title:"Social Awareness Program ",
  //   image:'/assets/images/causes/1580561002389.jpg'
  // }]
  async ngOnInit() {
    // await this.getAllItem();
  }
  getAllItem() {
    let url = 'getProjectList';
    this._loading.setLoading(true, url);
    this.coreService.get(url).subscribe(
      (data: any) => {
        this._loading.setLoading(false, url);
        this.causes = data.details;
        setTimeout(() => {
          $(".owl-carousel-3col").each(function () {
            var data_dots =
              $(this).data("dots") === undefined ? false : $(this).data("dots");
            var data_nav =
              $(this).data("nav") === undefined ? false : $(this).data("nav");
            var data_duration =
              $(this).data("duration") === undefined
                ? 4000
                : $(this).data("duration");
            $(this).owlCarousel({
              // rtl: THEMEMASCOT.isRTL.check(),
              autoplay: true,
              autoplayTimeout: data_duration,
              loop: true,
              items: 3,
              margin: 15,
              dots: data_dots,
              nav: data_nav,
              navText: [
                '<i class="pe-7s-angle-left"></i>',
                '<i class="pe-7s-angle-right"></i>',
              ],
              responsive: {
                0: {
                  items: 1,
                  center: false,
                },
                480: {
                  items: 1,
                  center: false,
                },
                600: {
                  items: 1,
                  center: false,
                },
                750: {
                  items: 2,
                  center: false,
                },
                960: {
                  items: 2,
                },
                1170: {
                  items: 3,
                },
                1300: {
                  items: 3,
                },
              },
            });
          });
        }, 1000);
      },
      (error) => {
        console.log(error);
        // this.toastr.error(error.statusText);
      }
    );
  }
  goToNavigate(value) {
    this.router.navigate(["/dashboard"], {
      queryParams: { donate: `${value}`, id: `donate` },
    });
  }

  openCausesDetails(data) {
    //alert();
    //console.log(data); //project_id

    //alert(data.project_id);

    this.title = "";
    this.content = "";

    switch (parseInt(data.project_id)) {
      case 5:
        this.title = "CHILD EDUCATION";
        this.content = `<p>DBN is committed to provide basic education to underprivileged children of urbanized slum and low income community areas.</p>
        <p><strong>Educational initiatives include-</strong></p>
        <ul>
        <li>Pre-school Education (3-6 yrs), school preparatory skills</li>
        <li>Non Formal Education (6-14 yrs, non-school going) for a basic and fundamental education</li>
        <li>Remedial Education (6-14 yrs school going) for complete after-school tuition support programme.&nbsp;</li>
        <li>Bridge Course (6-14 yrs drop-outs) to stimulate previously acquired basic knowledge.&nbsp;</li>
        </ul>
        <div>
        <p class="text-center"><strong>Our Child Education Centers</strong></p>
        <p><u><strong>DBN PAATHSHALA AT JANAK PURI SLUMS AND B2B JANAKPURI&nbsp;</strong></u><strong>&ndash;&nbsp;</strong>providesnon- formal basic education to non-school going and drop out children. We provide complete education support by fulfilling all their education related needs and requirements like complete tuition to school going children.&nbsp;</p>
        <p>&nbsp;<u><strong>PRATHAM GURUKUL AT NANGAL GAON, DELHI CANTT.&nbsp;</strong></u><strong>&ndash;</strong>&nbsp;Pratham Gurukul is an approach and a strategy for sowing the seed for love for education and school at the initial tender age. Before enrolling them in formal school they are provided with informal education assistance, a kind of preparatory course en-route to formalization.We provide them with complete holistic formative basics thus making them ready for next level of education.&nbsp;</p>
        <p><strong>MISSION ADMISSION</strong></p>
        <p>Many Under-privileged children sometimes are denied the right to get education as their parents do not have proper documents like birth certificate etc. DBN plays a major role in getting these children enrolled in various government schools and make sure to fulfill all their education needs thereafter.</p>
        </div>`;
        break;

      case 7:
        this.title = "BASIC NEEDS";
        this.content = `<p><strong>Feed the Hungry - </strong>Round the year, we conduct multiples of food donation drives as per request of our donors. You can also join us during the food distribution drive. Please feel free to get in touch with our team to conduct a food donation drive on your special occasions.</p>
          <p><strong>Clothes Donation Drive </strong>- Throughout the year, we collect all kinds of summer and winter clothes for children and adults at our Janakpuri collection centres.</p>
          <p>A small gesture of yours will help many under privileged people to stay happy during harsh summer / winter season. You too can help us to collect as many clothes from your neighborhood. We are located at West Delhi and nearby metro station is Janakpuri , just 5 minutes from our centre. <strong>Our address</strong>&nbsp;:&nbsp;DBN collection centre, B2B/306,Janakpuri,New Delhi -110058</p>
          <p><strong>Donate the Blanket- Save A Life </strong>- Every winter, Divine Basic Needs runs Blanket Distribution Campaign, where we provide warm blankets to the underprivileged living in the slums or streets under the open sky in the harsh winters . We try our best to reach out to needy people in Delhi NCR. There is no better gift than providing warm clothing to the needy during winter season. You can also join us to by donating blankets to the needy. This winter, become a part of our campaign</p>
          <p><strong>Donate An Educational Kit </strong>- In an attempt to motivate children to attend classes, DBN initiated the Educational kit program designed to ensure that basic school supplies is provided to every child. The School Kit consists of a summer and winter school uniform, school bag, books, notebooks, stationery pouch, pen, pencil, eraser, sharpener, ruler and colours. Aim is to empower each child with necessary tools of education. One can help us by sponsoring for educational kit.&nbsp;</p>
          <p><strong>Donate A Women Hygiene Kit </strong>- Women face challenges accessing sanitary pads: male-run medical stores, unaffordable costs and stigma and shame associated with menstruation. DBN team is creating awareness about menstrual hygiene and teaching underprivileged women to use sanitary pads instead of rags. We provide free high quality sanitary pads to those who do not have access to it. You can join us in our program by donating a hygiene kit which includes 2 panties, bathing soap and sanitary pads.</p>`;
        break;

      case 8:
        this.title = "Social Awareness Programs";
        this.content = `<p>Various awareness programmes in the form of workshops, talks, nukkad nataks, rallies are organized from time to time to make people aware about importance of education, environment, health, hygiene, voting rights, safety etc. You can help us conduct such programmes as an individual, corporates, schools, colleges or institutions.</p>`;
        break;

      case 4:
        this.title = "Health and Hygiene or Yoga and Meditation";
        this.content = `<p><p>Divine Basic Needs fulfills its most beautiful objective of spreading Smiles by organizing health and happiness workshops for kids and adults. Various Yoga and Meditations sessions are organized from time to time for enhancing ones personality &ndash; physically, emotionally and spiritually. Group Yoga and Meditation is practiced every early morning . If one is interested to join or organize any related workshops may write to us at <a href="mailto:divinebasicneeds@gmail.com">divinebasicneeds@gmail.com</a></p>
          <p>Medical Camps/ health check up camps are organised from time to time. Free medicines are distributed to the one in need.</p>`;
        break;

      case 6:
        this.title = "Women Empowerment";
        this.content = `<p><strong><u>Skill Development Training Program - &ldquo;</u><u>Badte</u><u>Kadam</u><u>&rdquo;</u></strong></p>
        <p>Nations that invest in women's employment, health and education are just more likely to have better outcome. Their children will be healthier and better educated. This is not just the right thing to do for us to hold up those women, support them, encourage their involvement; this is a strategic imperative. - Hillary Clinton.</p>
        <p>The innumerable women in the shanty ghettoes remain just anonymous as someone&rsquo;s wife or mother and in abject poverty. These woman dare not step out of their home, lest aside be financial independent. Giving them confidence to come out and work is an attempt to bring that much needed changes in the society. At DBN, these slum women are helped to be financially independent, to fight all odds, survive and succeed. They are empowered to stand for their rights, from being not throttled, from not being used and abused and help them break the shackles of poverty. With this very objective, DBN started the women empowerment programme &ldquo; Badte Kadam&rdquo;.</p>
        <p><strong>Divine </strong><strong>Silai</strong><strong> Training Centre (Affiliated by Singer India Limited)</strong></p>
        <p>Women who are illiterate or have attended schools only till primary get an opportunity to earn livelihood through this programme. DBN has set up a &ldquo;Divine Silai Training Centre&rdquo; to make women self-sufficient, confident and independent. These women can earn side by side to portraying their traditional roles as homemaker. Basic tailoring skills enable women to work from home and immediately start earning upon completion of their course. The course duration is 3 and 6 months for basic cutting &amp; sewing, with the participants progressing to the next level of training in art &amp; creativity. Financial independence and self-reliance contribute to women empowerment and help them reinforce their social status. The &ldquo;Divine Silai Training Centre&rdquo; is affiliated and recognized by Singer India Limited.</p>
        <p><strong>DIVINE BEAUTY TRAINING CENTRE</strong></p>
        <p>With an aim to improve the economic status of women living in slums or low income community areas, driven by the vision of &ldquo;Empowering Women&rdquo; with tools of sustainable livelihood, After setting up Divine Stitching training center for under- privileged young girls and ladies, we moved ahead in the field of Women&rsquo;s Welfare and Skill Development Training and initiated with Divine Beauty Training Centre.&nbsp;We impart beautician training to women who are socially and economically disadvantaged, don&rsquo;t have high academic credentials, are school/college drop-outs as for the beauty &amp; wellness segment, it is the &ldquo;skills&rdquo; which makes a difference rather than her formal academic background .</p>
        <p>In todayz modern world, beauty-parlour training is being considered a key means of transforming women into self-confident, earning, empowered women .The beauty &amp; wellness segment is one of very few sectors in the economy where the workforce is largely dominated by women. In the beauty training course which ranges from 4 months to 6 month of duration, women learn several techniques for beauty like eye brow making, facial, make-up, manicure, pedicure, mehndi, hair cut, wax etc. After getiing trained, Girls can join a salon or set up as freelance beauticians. This is another skill which provides&nbsp;for a balance of home and profession.</p>`;
        break;

      default:
        this.title = "";
        this.content = "";
        break;
    }

    this.showModal = true;
  }
  closeCausesDetails() {
    this.showModal = false;
  }
  ShowContent(_id) {
    this.openContentId = _id;
  }
  HideContent() {
    this.openContentId = -1;
  }
}
