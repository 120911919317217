import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-admin-event",
  templateUrl: "./admin-event.component.html",
})
export class AdminEventComponent implements OnInit {
  constructor() {};
  isShowList = true;
  blogs = [{
    image:"assets/images/photo_slider/Clothes donated.jpg",
    share:"24",
    comments:"214",
    likes:"895",
    date:"11",
    month:"Dec",
    title:"Clothes donated",
    description:"Divine Basic Needs NGO  distributed multiple thousands of clothes at Kirby Place Slums. Clothes donated by  all  of you were sorted at our NGO centre...packed  according to different age groups/Gender etc and  distributed to kids..ladies.. gents..oldies...Thanks to all kind hearted souls who were part of our clothes donation event...we brought back so many Smiles and blessings for u all.."
},{
  image:"assets/images/photo_slider/slider.jpg",
  share:"24",
  comments:"214",
  likes:"895",
  date:"14",
  month:"Nov",
  title:"Childrens celebration",
  description:"Divine Basic Needs  celebrated children's  day in a meaningful way by organizing Health and Hygiene Workshop on Day 1 in association  with Amity University. On Day 2 Children had fun time ..they  performed, danced and enjoyed games."
},{
  image:"assets/images/photo_slider/diwali.jpg",
  share:"24",
  comments:"214",
  likes:"895",
  date:"6",
  month:"Nov",
  title:"Diwali celebration",
  description:"On the occasion of Diwali, Refreshment boxes were distributed to the vulnerable  kids and ladies which were sponsored  by  Amit Bakery, CSM Diwali Gifts  distributed  to the kids were sponsored  by our beloved  teacher and volunteer Ms.Vinni"
}]
  ngOnInit(): void {}
  navigate(event){
    
  }
}
