import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'POST' || req.method === 'GET' || req.method === 'PUT' || req.method == 'DELETE') {
      if (req.url.match('assets')) {
        if (window.location.pathname.match('panel')) {
          // req = req.clone({
          //   url: environment.urltranslate + req.url
          // });
        }
      }else{
       if(req.url.includes('logIn') || req.url.includes('forgotPassword')){
        req = req.clone({
          url: environment.apiBase + req.url,
          // headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'})
        });
       }
       else if(!req.url.includes('createPatientPathologyTest') && !req.url.includes('createPatientRadiologyTest') && req.url.includes('createPatient') && !req.url.includes('createPatientWithToken')){
        req = req.clone({
          url: environment.apiBase + req.url,
          // headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'})
        });
       }else{


         if(req.url.includes('export')){
            req = req.clone({
              url: environment.apiBase + req.url,
              headers: new HttpHeaders({ 
                'token': localStorage.getItem('customerAccessToken') || '',
                'content-disposition': `attachment; filename=Doctor_detail-${new Date().getTime()}`,
                'content-type':'csv',
                'server':'uvicorn',
                'Access-Control-Expose-Headers': 'Content-Disposition',
                'transfer-encoding':'chunked'
              })
              
            });
         }else{
          
          req = req.clone({
            url: environment.apiBase + req.url,
            headers: new HttpHeaders({ 
              'token': localStorage.getItem('customerAccessToken') || '',
              //  'Content-Type': 'multipart/form-data',
            })
            
          });
         }
       }
      }
       
      return next.handle(req).pipe(    
        tap(event => {

      }, err => {
        // this.toastr.error('Fail', 'Unauthrized');

        // this.router.navigate(['/auth/login'])
        // if ((err.status === 401 || err.status === 440) && this.router.url !== '/login') {
        //   let title = 'Session expired. Please login again';
        //   // this.toastr.error('Fail!!!', 'Session expired. Please login again');
        //   this.authService.clearLocalStorage();
        //   return this.router.navigate(['/login']);
        // } else if (err.status && err.status !== 434) {
        //   if (err.error.error) {
        //     // this.toastr.error('error', err.error.error.message);
        //   }
        // }
      })
      );     
    } else {
      return next.handle(req);
    }
  }

}
