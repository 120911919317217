import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import * as jQuery from "jquery";
import { ToastrService } from "ngx-toastr";
import { CoreService } from "src/app/shared/guard/core.service";
import { LoadingService } from "src/app/shared/loading.service";

declare var $;
declare var Razorpay;

declare function loadingStart(text: string): any;
declare function loadingStop(): any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  form: FormGroup;
  project = [];
  loading: boolean = true;
  donates:any = false
  constructor(
    public coreService: CoreService,
    private route: ActivatedRoute,
    private _router: Router,
    private _loading: LoadingService,
    private toastr: ToastrService
  ) {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        // this._router.navigated = false;
        window.scrollTo(0,0);
      }
    });

    this.route.queryParamMap.subscribe((params) => {
      console.log(params);
      console.log(params["params"]);
      setTimeout(() => {
        // this.navigate(`#${params["params"]["id"]}`);
        this.donate(decodeURI(`${params["params"]["donate"]}`));
      }, 2000);
    });
  }
  ngOnInit() {
    this.creatForm();
    // this.getProjectList();
    this.initializeSliders();
  }

  goToDonation(){
    var targetOffset = $("#donation").offset().top; // Get the target element's offset from the top of the document
    $("html, body").animate({ scrollTop: targetOffset }, 1000);
  }
  creatForm() {
    this.form = new FormGroup({
      payment_type: new FormControl("type1", [Validators.required]),
      // donation_type: new FormControl('', [Validators.required]),
      donation_for: new FormControl("", [Validators.required]),
      currency_code: new FormControl("", [Validators.required]),
      amount: new FormControl("", [Validators.required]),
      contact: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
    });
  }

  donate(value) {
    this.form.controls.donation_for.reset(value);
  }
  get f() {
    return this.form.controls;
  }
  submit() {
    console.log(this.form.controls);
    let data = {
      amount: this.form.controls.amount.value,
      currency: this.form.controls.currency_code.value,
      receipt: "receipt",
      partial_payment: true,
      notes: {
        title: this.form.controls.donation_for.value,
        description: this.form.controls.donation_for.value,
        deadline: null,
        image: null,
        order_status: 0,
      },
    };
    this.coreService.post("createOrder", data).subscribe(
      (data: any) => {
        console.log(data);

        let RAZORPAY_OPTIONS = {
          key: "rzp_test_qOxkYNT2NQyYeU",
          secret: "eEidhigTsxUy1z74mmquVj0w",
          amount: this.form.controls.amount.value, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: this.form.controls.currency_code.value,
          name: this.form.controls.name.value,
          description: this.form.controls.donation_for.value,
          image: "/assets/images/Logo9.png",
          order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: (response) => {
            let body = {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            };
            this.coreService.post("createOrderPayments", body).subscribe(
              (resp: any) => {
                console.log(resp);
                let url="verifyPaymentSignature";
                  this._loading.setLoading(true, url);
                this.coreService.post(url, body).subscribe(
                  (res: any) => {
                    this._loading.setLoading(false, url);
                    if (res.message) {this.toastr.success("Success", "payment is successful");
                        setTimeout(()=>{this.toastr.success("Success", "payment is successful");},5000)
                    }
                  },
                  (error) => {
                    this._loading.setLoading(false, url);
                    console.log(error);
                    this.toastr.error("There is some problem as of now, Please try again in some time");
                  }
                );
              },
              (error) => {
                console.log(error);this.toastr.error("There is some problem as of now, Please try again in some time");
              }
            );
          },
          prefill: {
            name: this.form.controls.name.value,
            email: this.form.controls.email.value,
            contact: this.form.controls.contact.value,
          },
          notes: {
            address: "C3/396, First Floor, Janak Puri, New Delhi -110058",
          },
          theme: {
            color: "#3399cc",
          },
        };
        var rzp1 = new Razorpay(RAZORPAY_OPTIONS);
        rzp1.open();

        rzp1.on("payment.failed", function (response) {
          this.toastr.success("Success", response.error.reason);
          console.log(response);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          console.log(response.error.metadata.order_id);
          console.log(response.error.metadata.payment_id);
        });
      },
      (error) => {
        console.log(error);
        this.toastr.error("There is some problem as of now, Please try again in some time");
        // this.toastr.error(error.statusText);
      }
    );
  }

  // navigate(templateId) {
  //   this.loading = true;
  //   //loadingStart("Please wait...");

  //   setTimeout(() => {
  //     this.loading = false;
  //     loadingStop();
  //   }, 2000);

  //   console.log(templateId);
  //   let nav = $(templateId);
  //   let value = nav.offset().top - 180;
  //   if (templateId.includes("donate")) {
  //     value = nav.offset().top + 180;
  //   }

  //   //alert(value);
  //   jQuery("html, body").animate(
  //     {
  //       scrollTop: value,
  //     },
  //     200,
  //     "swing",
  //     function () {
  //       // window.location.hash = target
  //     }
  //   );
  // }
  initializeSliders() {
    // $("rev_slider_default").owlCarousel({
    //   stagePadding: 200,
    //   loop: true,
    //   margin: 10,
    //   items: 1,
    //   lazyLoad: true,
    //   nav: true,
    //   responsive: {
    //     0: {
    //       items: 1,
    //       stagePadding: 60,
    //     },
    //     600: {
    //       items: 1,
    //       stagePadding: 100,
    //     },
    //     1000: {
    //       items: 1,
    //       stagePadding: 200,
    //     },
    //     1200: {
    //       items: 1,
    //       stagePadding: 250,
    //     },
    //     1400: {
    //       items: 1,
    //       stagePadding: 300,
    //     },
    //     1600: {
    //       items: 1,
    //       stagePadding: 350,
    //     },
    //     1800: {
    //       items: 1,
    //       stagePadding: 400,
    //     },
    //   },
    // });
    $(".text-carousel, .image-carousel, .fullwidth-carousel").each(function () {
      var data_dots =
        $(this).data("dots") === undefined ? false : $(this).data("dots");
      var data_nav =
        $(this).data("nav") === undefined ? false : $(this).data("nav");
      var data_duration =
        $(this).data("duration") === undefined
          ? 4000
          : $(this).data("duration");
      $(this).owlCarousel({
        // rtl: THEMEMASCOT.isRTL.check(),
        autoplay: true,
        autoplayTimeout: data_duration,
        loop: true,
        items: 1,
        // dots: data_dots,
        // nav: data_nav,
        navText: [
          // '<i class="pe-7s-angle-left"></i>',
          // '<i class="pe-7s-angle-right"></i>',
        ],
      });
    });

    $(".owl-carousel-2col").each(function () {
      var data_dots =
        $(this).data("dots") === undefined ? false : $(this).data("dots");
      var data_nav =
        $(this).data("nav") === undefined ? false : $(this).data("nav");
      var data_duration =
        $(this).data("duration") === undefined
          ? 4000
          : $(this).data("duration");
      $(this).owlCarousel({
        // rtl: THEMEMASCOT.isRTL.check(),
        autoplay: true,
        autoplayTimeout: data_duration,
        loop: true,
        items: 2,
        margin: 15,
        dots: data_dots,
        nav: data_nav,
        navText: [
          '<i class="pe-7s-angle-left"></i>',
          '<i class="pe-7s-angle-right"></i>',
        ],
        responsive: {
          0: {
            items: 1,
            center: false,
          },
          480: {
            items: 1,
            center: false,
          },
          600: {
            items: 1,
            center: false,
          },
          750: {
            items: 2,
            center: false,
          },
          960: {
            items: 2,
          },
          1170: {
            items: 2,
          },
          1300: {
            items: 2,
          },
        },
      });
    });

    $(".owl-carousel-4col").each(function () {
      var data_dots =
        $(this).data("dots") === undefined ? false : $(this).data("dots");
      var data_nav =
        $(this).data("nav") === undefined ? false : $(this).data("nav");
      var data_duration =
        $(this).data("duration") === undefined
          ? 4000
          : $(this).data("duration");
      $(this).owlCarousel({
        // rtl: THEMEMASCOT.isRTL.check(),
        autoplay: true,
        autoplayTimeout: data_duration,
        loop: true,
        items: 4,
        margin: 15,
        dots: false,
        nav: true,
        navText: [
          // '<img src="assets/imgs/arrow-left-white.svg"/>',
          // '<img src="assets/imgs/arrow-right-white.svg"/>',
          `<button style="position: absolute;right:30px;"><i class="fa fa-angle-left"></i></button>`,
          `<button style="position: absolute;left:30px;"><i class="fa fa-angle-right"></i></button>`
        ],
        responsive: {
          0: {
            items: 1,
            center: true,
          },
          480: {
            items: 1,
            center: false,
          },
          600: {
            items: 3,
            center: false,
          },
          750: {
            items: 3,
            center: false,
          },
          960: {
            items: 3,
          },
          1170: {
            items: 4,
          },
          1300: {
            items: 4,
          },
        },
      });
    });

    $(".owl-carousel-5col").each(function () {
      var data_dots =
        $(this).data("dots") === undefined ? false : $(this).data("dots");
      var data_nav =
        $(this).data("nav") === undefined ? false : $(this).data("nav");
      var data_duration =
        $(this).data("duration") === undefined
          ? 4000
          : $(this).data("duration");
      $(this).owlCarousel({
        // rtl: THEMEMASCOT.isRTL.check(),
        autoplay: true,
        autoplayTimeout: data_duration,
        loop: true,
        items: 5,
        margin: 15,
        dots: data_dots,
        nav: data_nav,
        navText: [
          '<i class="pe-7s-angle-left"></i>',
          '<i class="pe-7s-angle-right"></i>',
        ],
        responsive: {
          0: {
            items: 1,
            center: false,
          },
          480: {
            items: 1,
            center: false,
          },
          600: {
            items: 2,
            center: false,
          },
          750: {
            items: 3,
            center: false,
          },
          960: {
            items: 4,
          },
          1170: {
            items: 5,
          },
          1300: {
            items: 5,
          },
        },
      });
    });

    $(".owl-carousel-6col").each(function () {
      var data_dots =
        $(this).data("dots") === undefined ? false : $(this).data("dots");
      var data_nav =
        $(this).data("nav") === undefined ? false : $(this).data("nav");
      var data_duration =
        $(this).data("duration") === undefined
          ? 4000
          : $(this).data("duration");
      $(this).owlCarousel({
        // rtl: THEMEMASCOT.isRTL.check(),
        autoplay: true,
        autoplayTimeout: data_duration,
        loop: true,
        items: 3,
        margin: 20,
        nav:true,
        dots: false,
        // nav: data_nav,
        navText: [
          // '<i class="pe-7s-angle-left"></i>',
          // '<img src="assets/imgs/arrow-right.svg"/> <p><span>3/</span>8</p>',
          `<button style="position: absolute;top: 230px;left: 503px;"><i class="fa fa-angle-left"></i></button>`,
          `<button style="position: absolute;top: 230px;left: -4px;"><i class="fa fa-angle-right"></i></button>`
        ],
        responsive: {
          0: {
            items: 1,
            center: false,
          },
          480: {
            items: 1,
            center: false,
          },
          600: {
            items: 2,
            center: false,
          },
          750: {
            items: 2,
            center: false,
          },
          960: {
            items: 2,
            center: false,
          },
          1000: {
            items: 3,
            center: false,
          },
          
        },
      });
    });
    ///for testing
    $(".owl-carousel-6colmn").each(function () {
      var data_dots =
        $(this).data("dots") === undefined ? false : $(this).data("dots");
      var data_nav =
        $(this).data("nav") === undefined ? false : $(this).data("nav");
      var data_duration =
        $(this).data("duration") === undefined
          ? 4000
          : $(this).data("duration");
      $(this).owlCarousel({
        // rtl: THEMEMASCOT.isRTL.check(),
        autoplay: false,
        autoplayTimeout: data_duration,
        loop: true,
        items: 3,
        margin: 20,
        nav:true,
        dots: false,
        // nav: data_nav,
        navText: [
          // '<i class="pe-7s-angle-left"></i>',
          // '<img src="assets/imgs/arrow-right.svg"/> <p><span>3/</span>8</p>',
          `<button style="position: absolute;top: 202px;left:1089px;"><i class="fa fa-angle-left"></i></button>`,
          `<button style="position: absolute;top: 202px;left: -4px;"><i class="fa fa-angle-right"></i></button>`
        ],
        responsive: {
          0: {
            items: 1,
            center: false,
          },
          480: {
            items: 1,
            center: false,
          },
          600: {
            items: 2,
            center: false,
          },
          750: {
            items: 2,
            center: false,
          },
          960: {
            items: 2,
            center: false,
          },
          1000: {
            items: 3,
            center: false,
          },
          
        },
      });
    });


    setTimeout(()=>{
      var sourceHeight = $("#donation-blogs").height(); // Get the height of the source element
      var sourceWidth = $("#donation-blogs").width();
      $(".owl-carousel-6col-img").height(sourceHeight -20);
      $(".owl-carousel-6col-img").width(sourceWidth -20); 
    },1000)
    
    



    $(".owl-carousel-4col").each(function () {
      var data_dots =
        $(this).data("dots") === undefined ? false : $(this).data("dots");
      var data_nav =
        $(this).data("nav") === undefined ? false : $(this).data("nav");
      var data_duration =
        $(this).data("duration") === undefined
          ? 4000
          : $(this).data("duration");
      $(this).owlCarousel({
        // rtl: THEMEMASCOT.isRTL.check(),
        autoplay: true,
        autoplayTimeout: data_duration,
        loop: true,
        items: 4,
        margin: 20,
        dots: false,
        nav: true,
        navText: [
          '<img src="assets/imgs/arrow-left-white.svg"/>',
          '<img src="assets/imgs/arrow-right-white.svg"/>',
        ],
        responsive: {
          0: {
            items: 1,
            center: false,
          },
          480: {
            items: 1,
            center: false,
          },
          600: {
            items: 3,
            center: false,
          },
          750: {
            items: 3,
            center: false,
          },
          960: {
            items: 3,
            center: false,
          },
          1000: {
            items: 4,
            center: false,
          },
          
        },
      });
    });

    $(".owl-carousel-7col").each(function () {
      var data_dots =
        $(this).data("dots") === undefined ? false : $(this).data("dots");
      var data_nav =
        $(this).data("nav") === undefined ? false : $(this).data("nav");
      var data_duration =
        $(this).data("duration") === undefined
          ? 4000
          : $(this).data("duration");
      $(this).owlCarousel({
        // rtl: THEMEMASCOT.isRTL.check(),
        autoplay: true,
        autoplayTimeout: data_duration,
        loop: true,
        items: 3,
        margin: 15,
        dots: data_dots,
        nav: data_nav,
        navText: [
          '<i class="pe-7s-angle-left"></i>',
          '<i class="pe-7s-angle-right"></i>',
        ],
        responsive: {
          0: {
            items: 1,
            center: false,
          },
          600: {
            items: 2,
            center: false,
          },
          750: {
            items: 2,
            center: false,
          },
          960: {
            items: 2,
            center: false,
          },
          1000: {
            items: 3,
            center: false,
          },
          
        },
      });
    });

    $(".owl-carousel-8col").each(function () {
      var data_dots =
        $(this).data("dots") === undefined ? false : $(this).data("dots");
      var data_nav =
        $(this).data("nav") === undefined ? false : $(this).data("nav");
      var data_duration =
        $(this).data("duration") === undefined
          ? 4000
          : $(this).data("duration");
      $(this).owlCarousel({
        // rtl: THEMEMASCOT.isRTL.check(),
        autoplay: true,
        autoplayTimeout: data_duration,
        loop: true,
        items: 8,
        margin: 15,
        dots: data_dots,
        nav: data_nav,
        navText: [
          '<i class="pe-7s-angle-left"></i>',
          '<i class="pe-7s-angle-right"></i>',
        ],
        responsive: {
          0: {
            items: 1,
            center: false,
          },
          600: {
            items: 2,
            center: false,
          },
          750: {
            items: 3,
            center: false,
          },
          960: {
            items: 5,
          },
          1170: {
            items: 8,
          },
          1300: {
            items: 8,
          },
        },
      });
    });

    // $(".rev_slider_default").revolution({
    //   sliderType: "standard",
    //   sliderLayout: "auto",
    //   dottedOverlay: "none",
    //   delay: 5000,
    //   navigation: {
    //     keyboardNavigation: "off",
    //     keyboard_direction: "horizontal",
    //     mouseScrollNavigation: "off",
    //     onHoverStop: "off",
    //     touch: {
    //       touchenabled: "on",
    //       swipe_threshold: 75,
    //       swipe_min_touches: 1,
    //       swipe_direction: "horizontal",
    //       drag_block_vertical: false,
    //     },
    //     arrows: {
    //       style: "zeus",
    //       enable: true,
    //       hide_onmobile: true,
    //       hide_under: 600,
    //       hide_onleave: true,
    //       hide_delay: 200,
    //       hide_delay_mobile: 1200,
    //       tmp: '<div class="tp-title-wrap">    <div class="tp-arr-imgholder"></div> </div>',
    //       left: {
    //         h_align: "left",
    //         v_align: "center",
    //         h_offset: 30,
    //         v_offset: 0,
    //       },
    //       right: {
    //         h_align: "right",
    //         v_align: "center",
    //         h_offset: 30,
    //         v_offset: 0,
    //       },
    //     },
    //     bullets: {
    //       enable: true,
    //       hide_onmobile: true,
    //       hide_under: 600,
    //       style: "metis",
    //       hide_onleave: true,
    //       hide_delay: 200,
    //       hide_delay_mobile: 1200,
    //       direction: "horizontal",
    //       h_align: "center",
    //       v_align: "bottom",
    //       h_offset: 0,
    //       v_offset: 30,
    //       space: 5,
    //       tmp: '<span class="tp-bullet-img-wrap">  <span class="tp-bullet-image"></span></span><span class="tp-bullet-title">{{title}}</span>',
    //     },
    //   },
    //   responsiveLevels: [1240, 1024, 778],
    //   visibilityLevels: [1240, 1024, 778],
    //   gridwidth: [1170, 1024, 778, 480],
    //   gridheight: [700, 768, 960, 720],
    //   lazyType: "none",
    //   parallax: {
    //     origo: "slidercenter",
    //     speed: 1000,
    //     levels: [
    //       5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 100, 55,
    //     ],
    //     type: "scroll",
    //   },
    //   shadow: 0,
    //   spinner: "off",
    //   stopLoop: "on",
    //   stopAfterLoops: 0,
    //   stopAtSlide: -1,
    //   shuffle: "off",
    //   autoHeight: "off",
    //   fullScreenAutoWidth: "off",
    //   fullScreenAlignForce: "off",
    //   fullScreenOffsetContainer: "",
    //   fullScreenOffset: "0",
    //   hideThumbsOnMobile: "off",
    //   hideSliderAtLimit: 0,
    //   hideCaptionAtLimit: 0,
    //   hideAllCaptionAtLilmit: 0,
    //   debugMode: false,
    //   fallbacks: {
    //     simplifyAll: "off",
    //     nextSlideOnWindowFocus: "off",
    //     disableFocusListener: false,
    //   },
    // });

    // $(".rev_slider_default").revolution({
    //   sliderType: "standard",
    //   sliderLayout: "auto",
    //   dottedOverlay: "none",
    //   delay: 5000,
    //   navigation: {
    //     keyboardNavigation: "off",
    //     keyboard_direction: "horizontal",
    //     mouseScrollNavigation: "off",
    //     onHoverStop: "off",
    //     touch: {
    //       touchenabled: "on",
    //       swipe_threshold: 75,
    //       swipe_min_touches: 1,
    //       swipe_direction: "horizontal",
    //       drag_block_vertical: false,
    //     },
    //     arrows: {
    //       style: "zeus",
    //       enable: true,
    //       hide_onmobile: true,
    //       hide_under: 600,
    //       hide_onleave: true,
    //       hide_delay: 200,
    //       hide_delay_mobile: 1200,
    //       tmp: '<div class="tp-title-wrap">    <div class="tp-arr-imgholder"></div> </div>',
    //       left: {
    //         h_align: "left",
    //         v_align: "center",
    //         h_offset: 30,
    //         v_offset: 0,
    //       },
    //       right: {
    //         h_align: "right",
    //         v_align: "center",
    //         h_offset: 30,
    //         v_offset: 0,
    //       },
    //     },
    //     bullets: {
    //       enable: true,
    //       hide_onmobile: true,
    //       hide_under: 600,
    //       style: "metis",
    //       hide_onleave: true,
    //       hide_delay: 200,
    //       hide_delay_mobile: 1200,
    //       direction: "horizontal",
    //       h_align: "center",
    //       v_align: "bottom",
    //       h_offset: 0,
    //       v_offset: 30,
    //       space: 5,
    //       tmp: '<span class="tp-bullet-img-wrap">  <span class="tp-bullet-image"></span></span><span class="tp-bullet-title">{{title}}</span>',
    //     },
    //   },
    //   responsiveLevels: [1240, 1024, 778],
    //   visibilityLevels: [1240, 1024, 778],
    //   gridwidth: [1170, 1024, 778, 480],
    //   gridheight: [700, 768, 960, 720],
    //   lazyType: "none",
    //   parallax: {
    //     origo: "slidercenter",
    //     speed: 1000,
    //     levels: [
    //       5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 100, 55,
    //     ],
    //     type: "scroll",
    //   },
    //   shadow: 0,
    //   spinner: "off",
    //   stopLoop: "on",
    //   stopAfterLoops: 0,
    //   stopAtSlide: -1,
    //   shuffle: "off",
    //   autoHeight: "off",
    //   fullScreenAutoWidth: "off",
    //   fullScreenAlignForce: "off",
    //   fullScreenOffsetContainer: "",
    //   fullScreenOffset: "0",
    //   hideThumbsOnMobile: "off",
    //   hideSliderAtLimit: 0,
    //   hideCaptionAtLimit: 0,
    //   hideAllCaptionAtLilmit: 0,
    //   debugMode: false,
    //   fallbacks: {
    //     simplifyAll: "off",
    //     nextSlideOnWindowFocus: "off",
    //     disableFocusListener: false,
    //   },
    // });
  }

  getProjectList() {
    let url = 'getProjectList';
    this._loading.setLoading(true, url);
    this.coreService.get(url).subscribe(
      (data: any) => {
        this._loading.setLoading(false, url);
        this.project = data.details;
      },
      (error) => {
        console.log(error);
        // this.toastr.error(error.statusText);
      }
    );
  }
}
