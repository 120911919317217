import { Component, OnInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-computer-education',
  templateUrl: './computer-education.component.html',
  styleUrls: ['./computer-education.component.css']
})
export class ComputerEducationComponent implements OnInit {

  constructor() { }
  gallery = [{
    image:"assets/imgs/computer_img1.png",   
  },{
  image:"assets/imgs/computer_img2.png",
  },{
  image:"assets/imgs/computer_img3.png",
  },
  {
    image:"assets/imgs/computer_img4.png",   
  },{
  image:"assets/imgs/computer_img5.png", 
  },{
  image:"assets/imgs/computer_img6.png", 
  },]

  ngOnInit(): void {
    this.initializeSliders();
  }

  initializeSliders() {
    $(".owl-carousel-6colmn").each(function () {
      var data_dots =
        $(this).data("dots") === undefined ? false : $(this).data("dots");
      var data_nav =
        $(this).data("nav") === undefined ? false : $(this).data("nav");
      var data_duration =
        $(this).data("duration") === undefined
          ? 4000
          : $(this).data("duration");
      $(this).owlCarousel({
        // rtl: THEMEMASCOT.isRTL.check(),
        autoplay: true,
        autoplayTimeout: data_duration,
        loop: true,
        items: 3,
        margin: 20,
        nav:true,
        dots: false,
        // nav: data_nav,
        navText: [
          // '<i class="pe-7s-angle-left"></i>',
          // '<img src="assets/imgs/arrow-right.svg"/> <p><span>3/</span>8</p>',
          `<button style="position: absolute;top:150px;left:1089px;"><i class="fa fa-angle-left"></i></button>`,
          `<button style="position: absolute;top: 150px;left: -4px;"><i class="fa fa-angle-right"></i></button>`
        ],
        responsive: {
          0: {
            items: 1,
            center: false,
          },
          480: {
            items: 1,
            center: false,
          },
          600: {
            items: 2,
            center: false,
          },
          750: {
            items: 2,
            center: false,
          },
          960: {
            items: 2,
            center: false,
          },
          1000: {
            items: 3,
            center: false,
          },
          
        },
      });
    });
  
 }


}
