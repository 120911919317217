import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Route } from "@angular/router";
import * as jQuery from 'jquery';
import { ToastrService } from "ngx-toastr";
import { CoreService } from "src/app/shared/guard/core.service";
import { LoadingService } from "src/app/shared/loading.service";
import { ViewportScroller } from "@angular/common";

declare var $;
@Component({
  selector: "app-get-involved",
  templateUrl: "./get-involved.component.html",
  styleUrls: ['./get-involved.component.css']
})
export class GetInvolvedComponent implements OnInit {
  imageUpload: File;
  constructor(private coreService: CoreService, private toastr: ToastrService, private router: ActivatedRoute,private _loading:LoadingService,private scroller: ViewportScroller ) { }
  form: FormGroup;
  file = "";
  submitted=false
  status =''
  ngOnInit(): void {
    this.createForm();
    this.router.queryParamMap.subscribe((params) => {
      if(params['params']['intern']=='intern'){
        this.scroller.scrollToAnchor("intern")
      }
    });
  }
  createForm() {
    this.form = new FormGroup({
      "firstName": new FormControl('', [Validators.required]),
      "lastName": new FormControl('', [Validators.required]),
      "email": new FormControl('', [Validators.required]),
      "gender": new FormControl('', [Validators.required]),
      "message": new FormControl('', [Validators.required]),
      "file": new FormControl('', [Validators.required]),
    });
  }
  get f() { return this.form.controls }
  submit() {
    this.submitted = true;
    if (!this.form.valid) {
      return false;
    } else {
      this.submitted = true;
      var formData = new FormData();
      formData.append('name',this.form.controls.firstName.value + " " + this.form.controls.lastName.value)
      formData.append('email', this.form.controls.email.value)
      formData.append('gender',(this.form.controls.gender.value))
      formData.append('branch','0')
      formData.append('message',this.form.controls.message.value)
      formData.append('file',this.imageUpload);
      formData.forEach((value, key) => {
        console.log(key, value);
      });
      let url="createVolunteer";
      this._loading.setLoading(true, url);
      this.coreService.postFile(url,formData).subscribe(
        (data: any) => {
          this._loading.setLoading(false, url);
          this.status = '200'
          this.submitted = false;
          this.toastr.success(
            "Success",
            "Your request has sent to our team, they will contact you soon"
          );
          formData=new FormData()
          this.form.reset();
        },
        (error) => {
          this._loading.setLoading(false, url);
          this.status = '500'
          console.log(error);
          this.toastr.error("There is some problem as of now, Please try again in some time");
        }
      );
      return true;
    }
  }
  handleFileInput(event) {
    console.log(event.target.files);
    if (event && event.target && event.target.files.length > 0) {
      this.imageUpload=event.target.files.item(0)
    //  const file = event.target.files.item(0);
      // const reader = new FileReader();
      // console.log("File path",this.file)
      // reader.readAsDataURL(file);
      // reader.onload = (e:any) => {
      //   this.imageUpload = e.currentTarget['result'];
      //   console.log(e)
//     this.file = event.target.files.item(0);
    
//     console.log(this.file);

      // };
    }
  }
}
