import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/shared/guard/auth.service";
import { CoreService } from "src/app/shared/guard/core.service";
import * as jQuery from "jquery";
declare var $;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
// export class LoginComponent implements OnInit {
//   form: FormGroup;
//   submitted: boolean = false;
//   constructor( public coreService: CoreService, private router: Router , public authService : AuthService) {}

//   ngOnInit(): void {
//     this.createForm();
//   }
//   createForm(){
//     this.form = new FormGroup({
//       userName: new FormControl('', [Validators.required]),
//       password: new FormControl('', [Validators.required]),
//     });
//   }

//   get f(){
//     return this.form.controls; 
//   }

//   login() {
//     this.submitted = true;
//     console.log(this.form)
//     if (this.form.invalid) {
//       return false;
//     } else {
//       this.submitted = true;

//       let data = {
//         username: this.form.controls.userName.value,
//         password: this.form.controls.password.value,
//       }
//       this.coreService.post('logIn', data).subscribe((data: any) => {
//         this.submitted = false;
//         if (data.code == 200) {
//           localStorage.clear();
//           localStorage.setItem('customerAccessToken' , data.access_token)
//           this.authService.setLocalStorage(data.detail);
//           this.router.navigate(['/dashboard'])
//         }
   
//       }, (error) => {
//         console.log(error)
//         // this.toastr.error(error.statusText);
//       });

//       return true;
//     }
//   }
// }
export class LoginComponent {
  username: string;
  password: string;
  rememberMe: boolean = false;
  submitted: boolean = false;
  loginInvalid: boolean = false;
  forgotPasswordClicked: boolean = false;
  signUpClicked: boolean = false;
  showPassword = false;
  @Output() callRegistration = new EventEmitter<any>();
  constructor(private router: Router,private authService:AuthService,private coreService:CoreService) { }
 
 
  openModal() {
    $('#myModal').modal('hide'); // Open Bootstrap modal using jQuery
//     $('#registermodal').modal('show');
// setTimeout(() => {
//   $('#registermodal').modal('show');
// }, 2000);
   setTimeout(()=>{this.callRegistration.emit()},1000)
// $('#myModal').on('hidden.bs.modal', function () {
  // Load up a new modal...
  // $('#registermodal').modal('show')
// })
  }
  
  
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;}

  onSubmit() {
    this.submitted = true;
    if (this.username === 'admin' && this.password === 'password') {
      this.loginInvalid = false;
      if (this.rememberMe) {
        localStorage.setItem('username', this.username);
      } else {
        localStorage.removeItem('username');
      }
      this.router.navigate(['/dashboard']);
    } else {
      this.loginInvalid = true;
    }
  }
  login() {
    this.submitted = true;
    // console.log(this.form)
    if (this.username==''&&this.password=="") {
      return false;
    } else {
      this.submitted = true;

      let data = {
        username: this.username,
        password: this.password,
      }
      this.coreService.post('logIn', data).subscribe((data: any) => {
        this.submitted = false;
        if (data.code == 200) {
          localStorage.clear();
          localStorage.setItem('customerAccessToken' , data.access_token)
          this.authService.setLocalStorage(data.detail);
          this.router.navigate(['/dashboard'])
        }
   
      }, (error) => {
        console.log(error)
        // this.toastr.error(error.statusText);
      });

      return true;
    }
  }

  onForgotPassword() {
    this.forgotPasswordClicked = true;
  }

  onSignUp() {
    this.signUpClicked = true;
  }
  
}


