import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { CoreService } from "src/app/shared/guard/core.service";
import { LoadingService } from "src/app/shared/loading.service";

@Component({
  selector: "app-blog-details",
  templateUrl: "./blog-details.component.html",
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {
  form: FormGroup;
  id ;
  submitted: boolean = false;
  constructor(public coreService: CoreService, private router: Router,
    private _loading: LoadingService,
    private toastr: ToastrService ,private route : ActivatedRoute) {

      this.route.queryParamMap.subscribe((params) => {
      // console.log(params)
      // console.log(params['params'])
      // setTimeout(()=>{
        this.id = params['params']['id'];
      // })
    });
    }

    
  blogs : any;
  comment : [];
  ngOnInit(): void {
    this.getBlog();
    this.getComment();
    this.createForm();
  }
  createForm(){
    this.form = new FormGroup({      
      "comment": new FormControl('', [Validators.required]),
    });
  }

  getBlog(){
    let isLike = this.blogs ? this.blogs.isLikes : false
    let url = 'getBlog?blog_id=' +this.id;
    this._loading.setLoading(true, url);
    this.coreService.get(url).subscribe((data: any) => {
      this._loading.setLoading(false, url);
      this.blogs = data.detail;
      this.blogs.day = moment(new Date(data.detail.created_at)).format('DD')
      this.blogs.month = moment(new Date(data.detail.created_at)).format('MMM')
      // if(isLike){
        this.blogs.isLikes = isLike;
      // }
      
    }, (error) => {
      console.log(error)
      // this.toastr.error(error.statusText);
    });
  }

  getComment(){
    let url = 'getComment?blog_id=' +this.id;
    this._loading.setLoading(true, url);
    this.coreService.get(url).subscribe((data: any) => {
      this._loading.setLoading(false, url);
      this.comment = data.detail;
    }, (error) => {
      console.log(error)
      // this.toastr.error(error.statusText);
    });
  }

  addLike(){
    if(!this.blogs.isLikes){
      let url = 'addLike?blog_id=' +this.id;
      this._loading.setLoading(true, url);
      this.coreService.get(url).subscribe((data: any) => {
        this._loading.setLoading(false, url);
        // this.comment = data.detail;
        this.blogs.isLikes = true;
        this.getBlog();
        this.getComment();
        this.toastr.success('Success', 'Like add successfully');

      }, (error) => {
        console.log(error)
        // this.toastr.error(error.statusText);
      });
    }
  }
  convertData(date){
    return moment(new Date(date)).format('DD/MM/YYYY')
  }

  addComment(){
    
      let comment = this.form.controls.comment.value;
      let data ={
        "blog_id": this.id,
        "comment": [
          comment
        ]
      }
      this.coreService.post('addComment?blog_id=' +this.id, data).subscribe((data: any) => {
        this.getBlog();
        this.getComment();
        this.toastr.success('Success', 'Comment add successfully');
      }, (error) => {
        console.log(error)
        // this.toastr.error(error.statusText);
      });
        
  }
}
