import { Component, OnInit } from '@angular/core';
import * as jQuery from "jquery";
declare var $;
@Component({
  selector: 'app-neki-ka-bazaar',
  templateUrl: './neki-ka-bazaar.component.html',
  styleUrls: ['./neki-ka-bazaar.component.css']
})
export class NekiKaBazaarComponent implements OnInit {
  imageUrl:any 
  openModal(i) {console.log("mmmmmmmmmmmmm",this.gallery[i].image)
    this.imageUrl=this.gallery[i].image
    $('#myModalImage').modal('show'); // Open Bootstrap modal using jQuery
  }

  constructor() { }
  gallery = [{
    image:"assets/imgs/Rectangle 281.png",   
  },{
  image:"assets/imgs/Rectangle 282.png",
  },{
  image:"assets/imgs/Rectangle 283.png",
  },

  {
    image:"assets/imgs/Rectangle 284.png",   
  },{
  image:"assets/imgs/Rectangle 285.png", 
  },{
  image:"assets/imgs/Rectangle 286.png", 
  },

  {
    image:"assets/imgs/Rectangle 287.png",   
  },{
  image:"assets/imgs/Rectangle 288.png", 
  },{
  image:"assets/imgs/Rectangle 289.png", 
  },


  {
    image:"assets/imgs/Rectangle 290.png",    
  },{
  image:"assets/imgs/Rectangle 291.png", 
  },{
  image:"assets/imgs/Rectangle 292.png", 
  }
]

  ngOnInit(): void {
    this.initializeSliders();
  }
  initializeSliders() {
  $(".owl-carousel-6colmn").each(function () {
    var data_dots =
      $(this).data("dots") === undefined ? false : $(this).data("dots");
    var data_nav =
      $(this).data("nav") === undefined ? false : $(this).data("nav");
    var data_duration =
      $(this).data("duration") === undefined
        ? 4000
        : $(this).data("duration");
    $(this).owlCarousel({
      // rtl: THEMEMASCOT.isRTL.check(),
      autoplay: true,
      autoplayTimeout: data_duration,
      loop: true,
      items: 3,
      margin: 20,
      nav:true,
      dots: false,
      // nav: data_nav,
      navText: [
        // '<i class="pe-7s-angle-left"></i>',
        // '<img src="assets/imgs/arrow-right.svg"/> <p><span>3/</span>8</p>',
        `<button style="position: absolute;top:150px;left:1089px;"><i class="fa fa-angle-left"></i></button>`,
        `<button style="position: absolute;top: 150px;left: -4px;"><i class="fa fa-angle-right"></i></button>`
      ],
      responsive: {
        0: {
          items: 1,
          center: false,
        },
        480: {
          items: 1,
          center: false,
        },
        600: {
          items: 2,
          center: false,
        },
        750: {
          items: 2,
          center: false,
        },
        960: {
          items: 2,
          center: false,
        },
        1000: {
          items: 3,
          center: false,
        },
        
      },
    });
  });

  $(" .fullwidth-carousel").each(function () {
    var data_dots =
      $(this).data("dots") === undefined ? false : $(this).data("dots");
    var data_nav =
      $(this).data("nav") === undefined ? false : $(this).data("nav");
    var data_duration =
      $(this).data("duration") === undefined
        ? 4000
        : $(this).data("duration");
    $(this).owlCarousel({
      // rtl: THEMEMASCOT.isRTL.check(),
      autoplay: true,
      autoplayTimeout: data_duration,
      loop: true,
      items: 1,
      // dots: data_dots,
      // nav: data_nav,
      dots: false,
      nav: true,
      navText: [
        
      ],
    });
  });
}
  

}
